import React, { useState, useEffect } from 'react'
import {
  Card, Typography, Grid, Box, styled, Chip, MoreVert, Divider, Tooltip,
  MenuItem, FormControl, Select, Stack, FilterList, IconButton, PeopleOutline,
  WatchLaterOutlined, LocationOnOutlined, ArrowForwardOutlined, Button, KeyboardBackspace
} from '../../utils/AllImportsHelper'
import SharedDrawer from '../../features/drawers/Drawer';
//  import DynamicSnackbar from '../../features/snackbar/Snackbar';
import { useSelector, useDispatch } from 'react-redux';
//  import { openSnackbar, closeSnackbar } from '../../features/snackbar/snackbarSlice';
import { openDrawer, closeDrawer } from '../../features/drawers/drawerSlice';
import FilterReferralJobDrawer from '../drawerBody/jobReferral/FilterReferralJobDrawer';
import ReferDrawer from '../drawerBody/jobReferral/ReferDrawer';
// import AdvertismentImg from '../../assets/images/image1.png';
import Advertisment from '../FeedPost.js/Advertisment'
// import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ApiService from '../../utils/ApiService';
import DOMPurify from 'dompurify';
import BreadcrumbsComponent from '../Breadcrumbs';


const MUICard = styled(Card)(({ theme }) => ({
  //    textAlign: 'center',
  padding: 0,
  border: '1px solid #E3E3E3',
  //     background: '#F8FAFF',
  //     cursor: 'pointer',

}));
const JobDescription = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [jobDetail, setJobDetail] = useState('')
  const dispatch = useDispatch();
  const filterReferralJobDrawer = (id) => {
    dispatch(openDrawer(id));
  }


  const referDrawer = (id) => {
    dispatch(openDrawer(id));
  }

  // useEffect(() => {
  //  ApiService.get('jobDetails',{},id).then(jobDetail => {
  //   if (jobDetail.status === 200) {
  //     setJobDetail(jobDetail.data.data)
  //   } else {
  //     // dispatch(openSnackbar({ dataLoad: true, message: jobDetail.message, severity: "error" }));
  //     alert('abc')
  //   } catch (error) {
  //     console.error('API request failed:', error);
  //     // setSnackBar({ dataLoad: true, message: 'API request failed', severity: 'error' });
  //   }

  //            // console.log(myReferral.data.data)
  //         })
  //   }, []);
  useEffect(() => {
    ApiService.get('jobDetails', {}, id)
      .then((jobDetail) => {
        setJobDetail(jobDetail.data.data);
      })
      .catch((error) => {
        // dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
        // alert(error);
      });
  }, []);


  const sanitizeAndStyleHTML = (html) => {
    return DOMPurify.sanitize(html)
      .replace(/<strong>/g, '') // Remove <strong> tags
      .replace(/<\/strong>/g, '') // Remove </strong> tags
      .replace(/<ul>/g, '<div>')
      .replace(/<\/ul>/g, '</div>')
      .replace(/<li>/g, ' ')
      .replace(/<\/li>/g, '<br>');
  };

  const textStyle = {
    whiteSpace: 'nowrap',   // Prevent text from wrapping to the next line
    overflow: 'hidden',    // Hide any overflow text
    textOverflow: 'ellipsis', // Display ellipsis when text overflows
  };
  console.debug("jobDetail", jobDetail)
  function onClickBackhandler() {
    navigate(-1)
  }
  return (
    <>
      <Box sx={{ minHeight: 'calc(95vh - 100px)' }}>
        <Grid direction="row" container>
          <Grid item xs={12} sm={8}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Box display="flex" >
                <Button  startIcon={<KeyboardBackspace />} onClick={onClickBackhandler}/>
                {/* <KeyboardBackspace sx={{ mr: 2 }} onClick={onClickBackhandler} /> */}
                <Typography variant='h6' display='flex'>{jobDetail?.jobTitle}</Typography>
              </Box>

              <Button variant='contained' onClick={() => referDrawer('referJob')}>Refer</Button>
              <SharedDrawer id='referJob'>
                {/* <Box> */}
                <ReferDrawer />
                {/* </Box> */}
              </SharedDrawer>
            </Stack>
          </Grid>
        </Grid>
        <Grid direction="row" container spacing={1} sx={{ my: 1 }}>
          {jobDetail &&
            <Grid item xs={12} sm={8}>
              <Card>

                <Card>
                  <Grid container spacing={1}>

                    <Grid item xs={12} sm={6} lg={4} display='flex'>
                      <Typography variant='body2' color='textSecondary'>Department</Typography>
                      <Typography variant='subtitle2'>: {jobDetail.department?.departmentName}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} display='flex'>
                      <Typography variant='body2' color='textSecondary'>Job Type</Typography>
                      <Typography variant='subtitle2'>: {jobDetail.jobType?.jobType}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} display='flex'>
                      <Typography variant='body2' color='textSecondary'>Job Location</Typography>
                      <Typography variant='subtitle2'>: {jobDetail?.city?.city}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} display='flex'>
                      <Typography variant='body2' color='textSecondary'>Total Experience</Typography>
                      <Typography variant='subtitle2'>: {jobDetail.experience}</Typography>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} sx={{display:{xs:'block',sm:'flex'}}}> */}
                    <Grid item xs={12} sm={6} lg={4} display='flex'>
                      <Typography variant='body2' color='textSecondary'>No. Of Position</Typography>
                      <Typography variant='subtitle2'>: {jobDetail.numberOfPositions}</Typography>
                    </Grid>
                  </Grid>
                </Card>
                <Card sx={{ my: 2, background: '#F9F9F9' }}>
                  <Typography variant='body1' fontWeight={500}>Job Description</Typography>
                  <Typography variant='body2' color='secondary.textSecondary' pt={1} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sanitizeAndStyleHTML(jobDetail.jobDescription)) }} />


                </Card>

              </Card>
            </Grid>
          }
          <Grid item xs={12} sm={4}>
            <Card>
              {/* <img src={AdvertismentImg} /> */}
              <Advertisment />
            </Card>

          </Grid>
        </Grid>
        {/* <DynamicSnackbar /> */}
      </Box>

    </>
  )
}

export default JobDescription