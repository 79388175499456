import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Card, Typography, Grid, Box, StepLabel, styled, KeyboardArrowLeft, KeyboardArrowRight, Stepper, Step, StepButton, Chip, MoreVert, Divider, Tooltip, RadioGroup, Radio, FormLabel,
  MenuItem, FormControl, InputLabel, Checkbox, Select, FormGroup, Stack, FilterList, IconButton, PeopleOutline, FormControlLabel,
  WatchLaterOutlined, LocationOnOutlined, ArrowForwardOutlined, Close, Button, Link, Avatar, Skeleton, CircularProgress, Alert
} from '../../../utils/AllImportsHelper'
import WordFile from "../../../assets/images/word.png";
import PDFFile from "../../../assets/images/pdf.png";
import ExcelFile from "../../../assets/images/excel.png"
import ImageFile from "../../../assets/images/picUpload.png"

import LinearProgress from '@mui/material/LinearProgress';
// import EditIcon from '@mui/icons-material/Edit';
import { tooltipClasses } from '@mui/material/Tooltip';
// import { useParams } from "react-router-dom";
// import Badge from '@mui/material/Badge';
// import AnimatedCoin from '../../../assets/images/like.svg';
// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import Person2Icon from '@mui/icons-material/Person2';
// import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
// import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
// import AddIcon from '@mui/icons-material/Add';
// import SchoolIcon from '@mui/icons-material/School';
// import WorkIcon from '@mui/icons-material/Work';
// import UploadIcon from '@mui/icons-material/Upload';
// import MedicationIcon from '@mui/icons-material/Medication';
// import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
// import BadgeIcon from '@mui/icons-material/Badge';
// import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
// import DeleteIcon from '@mui/icons-material/Delete';
import UploadFile from '@mui/icons-material/UploadFile';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import manfollow from '../../../assets/images/walk.gif';

// import SharedDrawer from '../../../features/drawers/Drawer';
import { useSelector, useDispatch } from 'react-redux';
import { openDrawer, closeDrawer } from '../../../features/drawers/drawerSlice';
// import { openSnackbar, closeSnackbar } from '../../../features/snackbar/snackbarSlice';
// import { closeDrawer } from '../../../features/drawers/drawerSlice';
// import DynamicSnackbar from '../../../features/snackbar/Snackbar';
import FormTextField from '../../forms/TextField';
import FormRadioGroup from '../../forms/RadioGroup';
import FormDateTimeField from '../../forms/DateTimePicker';
import ApiService from '../../../utils/ApiService';

// const HtmlTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} arrow classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.arrow}`]: {
//     color: '#2563EB',

//   },
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: '#fff',
//     color: '#000000',
//     fontWeight: '400',
//     boxShadow: '-1px 0px 6px #aaa',
//     // padding: "15px",
//     fontSize: theme.typography.pxToRem(14),
//     border: '1px solid #2563EB',
//     borderRadius: 2,
//   },
// }));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2563EB',

  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#000000',
    fontWeight: '400',
    boxShadow: '-1px 0px 6px #aaa',
    // padding: "15px",
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #2563EB',
    borderRadius: 2,
  },
}));


const ReferDrawer = ({parentId}) => {

  const steps = [
    'Provide a referral',
    'Referral Questions',
  ];
  const userData = useSelector((state) => state?.user?.userListName)
  // const familyMemberDetails = { 'familyMemberName': '', 'relation': '', 'dateOfBirth': '', 'dependent': '', 'whetherEmployed': '', 'employeeCode': '' };

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [expanded, setExpanded] = React.useState('panel01');
  const [referedBy, setReferredBy] = React.useState('byYou');
  const [referalCode, setReferalCode] = React.useState('');
  const [referredByUser, setReferredByUser] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [adharNumber, setAdharNumber] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [extensionErr, setRxtensionErr] = useState(false)
  const progressInfosRef = useRef(null);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [message, setMessage] = useState([]);
  const [fileUploadData, setfileUploadData] = useState();
  const [inputKey, setInputKey] = useState(0);
  const [dateValue, setDateValue] = React.useState(new Date());
  const [internalApplyJob, setInternalApplyJob] = useState({});
  // const referalJobId = useSelector((state) => state?.drawer?.drawerData?.jobId)

  // const [fileName, setFilename] = useState("")
  
  // let obj = [0, 1, 2, 3, 4];
  const allowedExtensions = ['.png', '.jpg', '.jpeg', '.xls', '.pdf', '.ppt', '.pptx', '.xlsx', '.jfif ', '.xlsm', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

  // useEffect(() => {
  //   // console.log({'referal job': referalJobId})
  //   // ApiService.get('referalJob',{},referalJobId)
  //   // .then((response) => {
  //   //   console.log({response})
  //   // })
  //   // .catch((error) => {
  //   //   console.log({error})
  //   //   // dispatch(openSnackbar({dataLoad: true, message: error.message, severity: "error"}));
  
  //   // });
  // }, [])

  const selectFiles = (event) => {

    const file = event.target.files[0];
    console.log(file)

    if (file) {
      const invalidFiles = [];
      const fileExtension = '.' + file.name.split('.').pop();
      if (allowedExtensions.includes(fileExtension.toLowerCase())) {
        const imageUrl = URL.createObjectURL(file);
        setSelectedFiles([file]);
        setImagePreviews([imageUrl]);
        setProgressInfos({ val: [] });
        setMessage([]);
        setInputKey(inputKey + 1);
        setfileUploadData([file]);
        const progressInfo = {
          percentage: 0,
          fileName: file.name,
          fileSize: file.size
        };
        progressInfosRef.current = {
          val: [progressInfo],
        };
        upload(0, file);
        setRxtensionErr(false)
      } else {
        event.target.value = null; // Clear the input
        setRxtensionErr(true)
      }

    }

  };

  const handleRemove = (index) => {
    let removeFiles = [...selectedFiles];
    removeFiles.splice(index, 1);
    // setState({ uploadedFiles });
    setSelectedFiles([...removeFiles]);

    let removeImagePreviews = [...imagePreviews];
    removeImagePreviews.splice(index, 1);
    setImagePreviews(removeImagePreviews);

    let removeProgressInfos = [...progressInfos.val];
    removeProgressInfos.splice(index, 1);
    setProgressInfos({ val: removeProgressInfos });
  }

 
  function formatBytes(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
  }

  const upload = async (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];

    setProgressInfos({ val: _progressInfos });

  };

  // const uploadDrawer = (id) => {
  //   dispatch(openDrawer(id));
  // }

  // const cancel = (id) => {
  //   dispatch(closeDrawer(id));
  // }
  const applyJobId = useSelector((state) => state?.drawer?.drawerData?.jobId)
  const handleApplyJob = async (jobId, userId) => {
    try {
    //   const apply = {
    //     jobId: applyJobId,
    //     lastName: userData.lastName,
    //     firstName:  userData.firstName,
    //     applicantEmai:  userData.email,
    //     applicantMsg: textFieldValue,
    //     file: fileUploadData[0]   };
    const formData = new FormData();
formData.append('jobId', applyJobId);
formData.append('lastName', lastName);
formData.append('firstName', firstName);
formData.append('referralCode', referalCode);
formData.append('applicantEmail', userData.email);
// formData.append('applicantMsg', textFieldValue);
formData.append('referralBy', userData.userName);
formData.append('phoneNumbe', contact);
// formData.append('app', getAppToken());

// Append selected files to the FormData
fileUploadData.forEach((file, index) => {
//   formData.append(`file${index + 1}`, file);
formData.append(`file`, file);
});
      const response = await ApiService.postAdopter('applyJob', formData);

    //   const updatedJob = { ...selectedJob, postSaved: false };
    setInternalApplyJob(response);

      // dispatch(openSnackbar({ dataLoad: true, message: 'Data stored succesfully', severity: "info" }));
      dispatch(closeDrawer(parentId));
    } catch (error) {
      // Handle API call error
      // dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
    }
    // fetchData()
    // setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const handleDrawerClose = () => {
    dispatch(closeDrawer(parentId));
  };

  const getStepJSX = () => {
    switch (activeStep) {
      case 0: return (
        <>
          <Box>
            <FormControl sx={{ pb: 1 }}>
              <FormRadioGroup 
                defaultValue="byYou"
                value={referedBy}
                setTextField={setReferredBy} 
                controls={[{value: "byYou", label: "Refer by you"},{value: "bySomeoneElse", label: "Refer by someone else"}]} />
            </FormControl>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
              <FormTextField id="referralCode" label="Referral Code" variant="outlined" value={referalCode} setTextField={setReferalCode} />
              <FormTextField id="referralBy" label="Referral By" variant="outlined"  value={referredByUser} setTextField={setReferredByUser} />
              <FormTextField id="fname" label="First Name" variant="outlined"  value={firstName} setTextField={setFirstName} required={true} />
              <FormTextField id="lname" label="Last Name" variant="outlined"  value={lastName} setTextField={setLastName} required={true} />
              <FormTextField id="aadhaarNumber" type='number' inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} label="Aadhaar Number" variant="outlined"  value={adharNumber} onChange={(event) => {
                setAdharNumber(event.target.value);
              }} setTextField={setAdharNumber} />
              <FormDateTimeField value={dateValue} setTextField={setDateValue} label={"DOB"} />
              <FormTextField id="contactNumber" type='number' inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} label="Contact Number" variant="outlined"  value={contact} onChange={(event) => {
                setContact(event.target.value);
              }} setTextField={setContact} />
              <FormTextField id="email" type='email' label="Email" variant="outlined"  value={email} setTextField={setEmail} required={true} />

            </Box>

            <Box sx={{ mb: 2 }}>
              {/* <Box> */}

              <Stack
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: "0.5rem",
                  border: "2px dashed rgba(0, 0, 0, 0.12)",
                  borderRadius: "12px",
                  backgroundColor: "rgba(201, 201, 201, 0.1)",
                  ":hover": { backgroundColor: "#DBEAFE", borderColor: "var(--color-mui-primary-blue)" },
                }}
              >
                <Box className="upload-file-icon" sx={{ p: "1rem 0rem" }}>
                  <Avatar sx={{ height: '2.5rem', width: '2.5rem', backgroundColor: '#1976D21F' }}>
                    <UploadFile color="primary" />
                  </Avatar>
                </Box>
                <Box className="upload-btn-wrapper">
                  <span>
                    <Link className="btn" sx={{ textDecoration: "underline", cursor: "pointer" }}>
                      Click to upload
                    </Link>
                    <input
                      key={inputKey}
                      type="file"
                      name="myfile"
                      multiple
                      accept=".png, .jpg, .jpeg, .xls, .pdf, .jfif, .ppt, .pptx, .xlsx, .xlsm, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      onChange={selectFiles}
                    />

                  </span>
                  <span> or drag and drop *</span>
                </Box>
                <Box m='1rem 0rem'>
                  <Typography variant="caption" color="text.secondary" display="block" gutterBottom> PNG, JPG, XLS,PDF and PPT</Typography>
                  {extensionErr ? <Alert variant="none" sx={{ color: "red" }} severity="error">
                    Please select valid file(s)
                  </Alert> : ""}
                </Box>
              </Stack>

              <Box sx={{ ml: '3rem' }}>
                {progressInfos &&
                  progressInfos.val.length > 0 &&
                  progressInfos.val.map((progressInfo, index) => (
                    <Box key={index} sx={{ my: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={2} md={2}>
                          <Box>
                            {(selectedFiles?.[index]?.name.endsWith(".docx")) ?
                              (
                                <>
                                  <img
                                    className="preview"
                                    src={WordFile}
                                    alt={"image-" + index}
                                    key={index}
                                  />
                                </>
                              )
                              : selectedFiles?.[index]?.name.endsWith(".pdf") ? (
                                <img
                                  className="preview"
                                  src={PDFFile}
                                  alt={"image-" + index}
                                  key={index}
                                />
                              ) : selectedFiles?.[index]?.name.endsWith(".xlsx") ||
                                selectedFiles?.[index]?.name.endsWith(".xls") ||
                                selectedFiles?.[index]?.name.endsWith(".csv") ? (
                                <img
                                  className="preview"
                                  src={ExcelFile}
                                  alt={"image-" + index}
                                  key={index}
                                />
                              ) : (
                                <img height={30} width={30} className="preview" src={ImageFile} alt={"image-" + index} key={index} />
                              )
                            }
                          </Box>
                        </Grid>
                        <Grid item xs={8} md={8}>
                          <Box>
                            <Box>
                              <HtmlTooltip title={progressInfo.fileName}>
                                <span>
                                  {progressInfo.fileName.length > 28
                                    ? progressInfo.fileName.substring(0, 28) + '...'
                                    : progressInfo.fileName}
                                </span>
                              </HtmlTooltip>
                            </Box>
                            <Box sx={{ my: 1 }}>
                              <Stack direction="row" spacing={2}>
                                <span><li>{formatBytes(progressInfo.fileSize)}</li></span>

                                <span>
                                  {message.length > 0 && (
                                    <Box className="alert alert-secondary" role="alert">
                                      <li>
                                        {message[index]}
                                      </li>
                                    </Box>
                                  )}
                                </span>
                              </Stack>
                            </Box>
                            <Box>
                              <LinearProgress variant="determinate" value={100} sx={{ '& .MuiLinearProgress-bar': { backgroundColor: 'red' } }} />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={2} md={2}>
                          <Box sx={{ mb: 1 }}>
                            <IconButton aria-label="upload picture" component="label" onClick={() => handleRemove(index)}>
                              <Close />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
              </Box>
            </Box>

          </Box>
        </>
      );





      case 1: return (
        <>
          <Box>  
            <TextField fullWidth
            sx={{my:2}}
          id="Q1"
          label="Q1. Why do you think this position is a good fit for a candidate you are referring?"
          multiline
          rows={3}
          variant="outlined"
          defaultValue="Default Value"
        />
         <TextField fullWidth
          id="Q2"
          label="Q2. What skills make your candidate the right person for this job?"
          multiline
          rows={3}
          variant="outlined"
           />
           <TextField fullWidth
           sx={{my:2}}
          id="Q3"
          label="Q3. Does your candidate communicate well?"
          multiline
          rows={3}
          variant="outlined"
           />
           <TextField fullWidth
          id="Q4"
          label="Q4. Does your candidate communicate well?"
          multiline
          rows={3}
          variant="outlined"
           />        
          </Box>
        </>
      );



      default: return (<></>);
    }
  }

  const [activeNextBtn, setActiveNextBtn] = useState(true);
  const [currentStepJSX, setCurrentStepJSX] = React.useState(getStepJSX());

  React.useEffect(() => {
    setCurrentStepJSX(getStepJSX());
  }, [activeStep, expanded, currentStepJSX]);

  useEffect(() => {
    if(firstName && lastName && email && selectedFiles){
      setActiveNextBtn(false);
    }
  }, [firstName, lastName, email, selectedFiles])

  const goToPreviousStep = () => {
    if (activeStep !== 0) {
      setExpanded('panel' + (activeStep - 1) + '1');
      setActiveStep(activeStep - 1);
    }
  };

  const goToNextStep = () => {
    if (activeStep !== steps.length - 1) {
      let comp = completed;
      comp[activeStep] = true;
      setCompleted(comp);
      setExpanded('panel' + (activeStep + 1) + '1');
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <>
      <Box sx={{width:{sm:'40rem',height:'100%'}}}>
        <Stack sx={{ backgroundColor: "#DBEAFE" }}>
          <Box sx={{ p: '.5rem .1rem' }}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={10}>
                <Box pl='1rem' display='flex'>
                  <Typography variant="body1" color="text.primary" fontWeight="700" display="block" >Referral Questions</Typography>
                </Box>
              </Grid>
              <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton aria-label="Close Drawer" component="label" onClick={handleDrawerClose} color='black'>
                  <Close sx={{ color: 'black' }} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <Box sx={{ borderRadius: "12px", py: '10px', m: 3, background: "var(--background-page-btn-section)" }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', padding: "var(--padding-horizontal-1)" }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', columnGap: "var(--column-gap)" }}>
              {activeStep === 0 ?
                <IconButton onClick={() => { goToPreviousStep(); }} size="small" sx={{ background: "var(--background-page-btn-inactive)", borderRadius: "var(--border-radius-button)", '&:hover': { background: "var(--background-page-btn-inactive)" } }}>
                  <KeyboardArrowLeft />
                </IconButton> : <IconButton onClick={() => { goToPreviousStep(); }} size="small" sx={{ background: "var(--background-page-btn-active)", borderRadius: "var(--border-radius-button)", '&:hover': { background: "var(--background-page-btn-active-hover)" } }}>
                  <KeyboardArrowLeft sx={{ color: 'var(--color-white)' }} />
                </IconButton>
              }
              {activeStep !== steps.length - 1 ?
                <IconButton onClick={() => { goToNextStep(); }} size="small" sx={{ background: "var(--background-page-btn-active)", borderRadius: "var(--border-radius-button)", '&:hover': { background: "var(--background-page-btn-active-hover)" } }} disabled={activeNextBtn} >
                  <KeyboardArrowRight sx={{ color: 'var(--color-white)' }} />
                </IconButton> : <IconButton onClick={() => { goToNextStep(); }} size="small" sx={{ background: "var(--background-page-btn-inactive)", borderRadius: "var(--border-radius-button)", '&:hover': { background: "var(--background-page-btn-inactive)" } }} disabled={activeNextBtn} >
                  <KeyboardArrowRight />
                </IconButton>

              }
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', columnGap: "var(--column-gap)" }}>
              <Button variant="outlined" size="small" sx={{ '&:hover': { background: "var(--background-page-btn-inactive-hover)" } }}>
                Reset
              </Button>
              {activeStep !== steps.length - 1 ?
                <Button onClick={() => { goToNextStep(); }} variant="contained" size="small" sx={{ '&:hover': { background: "var(--background-page-btn-active-hover)" } }} disabled={activeNextBtn} >
                 Next
                </Button> : <Button  onClick={() => handleApplyJob()} variant="contained" size="small" sx={{ '&:hover': { background: "var(--background-page-btn-active-hover)" } }}>
                  Save
                </Button>

              }
            </Box>
          </Box>
        </Box>

        <Card sx={{ mx: 3, mb: 2, backgroundColor: "var(--background-white)", borderRadius: "var(--border-radius-accordian)", paddingTop: "var(--padding-top-2)", position: 'relative', boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.12)' }}>
          <Grid container spacing={2}>
            <Grid item sm={9}>
              {/* <Box sx={{ position: 'absolute', left: 'clamp(calc(20% - 1rem),calc(10% * ' + (activeStep + 1) + ' - (-5rem)),calc(40% - 1rem)) ', top: '0rem' }}>
                <img src={manfollow} alt="My Image" style={{ height: "3rem" }} />
              </Box> */}
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={'steps' + index} completed={completed[index] && activeStep !== index}>
                    <StepLabel><Typography variant='body1'>{label}</Typography></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
        </Card>

        <Box sx={{ height: '50vh', overflow: 'auto', px: 3 }}>
          {currentStepJSX}
        </Box>
        {/* <DynamicSnackbar /> */}
      </Box>
    </>
  )
}

export default ReferDrawer