import React, { useEffect, useState } from 'react'
import {
  Typography, Grid, Paper, Box, Avatar, styled, Tooltip,
  Stack, IconButton,
  Button, Close
} from '../../../utils/AllImportsHelper'
import { useDispatch } from 'react-redux';
import { closeDrawer } from '../../../features/drawers/drawerSlice';
import ApiService from '../../../utils/ApiService';
import { Link } from 'react-router-dom';
import SkeletonCard from '../../MoreComponent/SkeletonCard';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.08)',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: 'theme.palette.text.secondary',
}));

const ViewProfile = (userId, parentId) => {
  const id = userId.userId
  const [userProfile, setUserProfile] = useState([]);
  useEffect(() => {
    let params = { toolsUser: 'null' }
    ApiService.get(`user`, params, id).then((userProfile) => {
      setUserProfile(userProfile.data.data);
    });
  }, []);

  const dispatch = useDispatch();
  const handleDrawerClose = () => {
    dispatch(closeDrawer(parentId));
  };

  const textStyle = {
    whiteSpace: 'nowrap',   // Prevent text from wrapping to the next line
    overflow: 'hidden',    // Hide any overflow text
    textOverflow: 'ellipsis', // Display ellipsis when text overflows
  };

  function formatDate(dateString) {
    // Create a Date object from the input date string
    const inputDate = new Date(dateString);
    // Define an array for month names
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'
    ];
    // Extract day and month from the input date
    const day = inputDate.getDate();
    const monthIndex = inputDate.getMonth();
    // Format the date as "DD Month"
    const formattedDate = `${day} ${months[monthIndex]}`;
    return formattedDate;
  }
  return (
    <Box sx={{ width: { sm: '30rem' } }}>
      <Stack sx={{ backgroundColor: "#DBEAFE" }}>
        <Box sx={{ p: '.5rem .1rem' }}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={10}>
              <Box pl='1rem' display='flex'>
                <Typography variant="body1" color="text.primary" fontWeight="700" display="block" >Profile</Typography>
              </Box>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton aria-label="Close Drawer" component="label" onClick={handleDrawerClose} color='black'>
                <Close sx={{ color: 'black' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      {userProfile.length != '' ?
        <Box sx={{ height: '80vh', overflow: 'auto', p: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item >
                  <Avatar sx={{ width: 60, height: 60 }}> <img src={userProfile.userAvatar} width='100%' /></Avatar>
                </Grid>
                <Grid item xs={9.5} sm={9.5}>
                  <Tooltip title={userProfile.userName + ' ' + userProfile.lastName}>
                    <Typography variant="body1" style={textStyle} fontWeight={500} >{userProfile.userName} {userProfile.lastName}</Typography>
                  </Tooltip>
                  <Typography variant="subtitle2" color='secondary.textSecondary'>{userProfile?.designation?.designation}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} container spacing={1} py={2}>
                <Grid item xs={6} sm={4} color={'#475569'}>
                  <Typography variant="subtitle2" >Email ID:</Typography>
                </Grid>
                <Grid item xs={6} sm={8}>
                  <Typography variant="subtitle2">{userProfile.email}</Typography>
                </Grid>
                <Grid item xs={6} sm={4} color={'#475569'}>
                  <Typography variant="subtitle2">Report To:</Typography>
                </Grid>
                <Grid item xs={6} sm={8}>
                  <Typography variant="subtitle2">{userProfile?.supervisorName?.name} {userProfile?.supervisorName?.lastName}</Typography>
                </Grid>
                <Grid item xs={6} sm={4} color={'#475569'}>
                  <Typography variant="subtitle2">BirthDay:</Typography>
                </Grid>
                <Grid item xs={6} sm={8}>
                  <Typography variant="subtitle2">{formatDate(userProfile.dateOfBirth)}</Typography>
                </Grid>
                <Grid item xs={6} sm={4} color={'#475569'}>
                  <Typography variant="subtitle2">City:</Typography>
                </Grid>
                <Grid item xs={6} sm={8}>
                  <Typography variant="subtitle2">{userProfile?.address}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {userProfile.skills?.length > 0 && (
            <>
              <Typography variant="body1" fontWeight={500} py={2}>
                Skills
              </Typography>
              <Grid container spacing={2}>
                {userProfile.skills?.map((skill, index) => (
                  <Grid item sm={2.5} key={index}> {/* Key should be added to each mapped item */}
                    <Typography variant="body2" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.08)', p: 1, textAlign: 'center', borderRadius: '5px' }} >{skill?.skill}</Typography>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          <Typography variant="body1" fontWeight={500} py={2} >Tools</Typography>
          <Stack direction="row" spacing={2}>
            {userProfile.toolsUser?.map((tools) => (
              <Item key={tools.id}>{tools?.tool.replace(/&nbsp;/g, '').replace(/(<([^>]+)>)/ig, "")}</Item>
            ))}
          </Stack>
        </Box>
        : <SkeletonCard />
      }
      <Box sx={{ backgroundColor: 'white', width: '100%', position: 'absolute', bottom: '0', mt: '4rem', height: '4rem' }}>
        <Stack sx={{ mx: '1rem', pt: '1rem' }} direction="row" spacing={1}>
          <Button component={Link} to={`/user-view-profile/${userProfile.userId}`} variant="contained" size="small" >FULL VIEW</Button>
          <Button variant="text" size="small" onClick={handleDrawerClose}>CANCEL</Button>
        </Stack>
      </Box>
    </Box>
  )
}

export default ViewProfile