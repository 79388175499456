import React, { useState, useEffect } from 'react'
import {
    Card, Typography, Grid, Box, styled, Chip, MoreVert, Divider, Tooltip, RadioGroup, Radio, FormLabel,
    MenuItem, FormControl, InputLabel, Checkbox, Select, FormGroup, Stack, FilterList, IconButton, PeopleOutline, FormControlLabel,
    WatchLaterOutlined, LocationOnOutlined, ArrowForwardOutlined, Button, Close
} from '../../../utils/AllImportsHelper'
import { useDispatch } from 'react-redux';
import { closeDrawer } from '../../../features/drawers/drawerSlice';
import ApiService from '../../../utils/ApiService';

const FilterFindAssociateDrawer = ({onUpdateFilters,parentId}) => {
    // console.log({props,parentId})
    const [country, setCountry] = React.useState('');

    const changeCountrySelectValue = (event) => {
        setCountry(event.target.value);
    };

    const [city, setCity] = React.useState('');

    const changeCitySelectValue = (event) => {
        setCity(event.target.value);
    };

    const [department, setDepartment] = React.useState([]);
    const [departmentData, setDepartmentData] = useState('');
    // console.log({departmentData})
    const [branch, setBranch] = useState([]);
    const [branchChange, setBranchChange] = useState('');
    const [skill, setSkill] = React.useState([]);
    const [selectSkill, setSelectSkill] = React.useState([]);
    const [experience, setExperience] = React.useState(
        [
            { name: 'junior', label: 'Junior (0-2 Years)', id:'0-2', checked: false },
            { name: 'midLevel', label: 'Mid Level (3-7 Years)', id:'3-7', checked: false },
            { name: 'senior', label: 'Senior (8+ Years)', id:'8-50', checked: false },
            ]
    );
   
    const [experienceLevels, setExperienceLevels] = useState([]);
    // console.log(experienceLevels)

    useEffect(() => {
        ApiService.get('orgDepartment').then(department => {
            setDepartment(department.data.data)
                //  console.log(department.data.data)
              })

              ApiService.get('branch').then(branch => {
                setBranch(branch.data.data)
                    //  console.log(branch.data.data)
                  })

                  ApiService.get('skills').then(skill => {
                    setSkill(skill.data.data)
                        //  console.log(skill.data.data)
                      })
        }, []);

        const changeDepartmentSelectValue = (event) => {
            setDepartmentData(event.target.value);
            // console.log(event.target.value,{event})
            // ApiService.get('orgDepartmentUser').then(department => {
            //     setDepartment(department.data.data)
            //          console.log(department.data.data)
            //       })
        };

        const handleBranchChange = (event) => {
            setBranchChange(event.target.value);
            // console.log(event.target.value,{event})
        };

        // const experience = [
        //     { name: 'junior', label: 'Junior (0-2 Years)', id:'0-2' },
        //     { name: 'midLevel', label: 'Mid Level (3-7 Years)', id:'3-7' },
        //     { name: 'senior', label: 'Senior (8+ Years)', id:'8-50' },
        //     ];

//         const selectExperienceLevel = (event) => {
// //             const idParts = event.target.value.split('-'); // Split the id by '-'
// //   const min = parseInt(idParts[0]); // Parse the first part as an integer
// //   const max = parseInt(idParts[1]); // Parse the second part as an integer

//   // Store the min and max values in a variable, e.g., total
// //   const selectExp = { min, max };

//   // Set the selected experience level as an object with min and max values
// //   setExperienceLevels({ min, max });
//             // setExperienceLevels(event.target.value);
//             //  console.log({ min, max })
//             const updatedExperienceLevel = experience.map((s) =>
//     s.id === event.target.id
//       ? { ...s, checked: event.target.checked }
//       : s
//   );
//   setExperience(updatedExperienceLevel);

//   // Update selectedSkillsArray based on selected skills
//   const updatedSelectedExpArray = updatedExperienceLevel
//     .filter((s) => s.checked)
//     .map((s) => s.id);

//     setExperienceLevels(updatedSelectedExpArray);
//         };

const selectExperienceLevel = (event) => {
    const updatedExperienceLevel = experience.map((s) =>
      s.id === event.target.id ? { ...s, checked: event.target.checked } : s
    );
    setExperience(updatedExperienceLevel);

    // Update selectedExperienceLevels based on selected experience levels
    const updatedSelectedExpArray = updatedExperienceLevel
      .filter((s) => s.checked)
      .map((s) => s.id);

    setExperienceLevels(updatedSelectedExpArray);
  };

    const dispatch = useDispatch();
    const handleDrawerClose = () => {
        dispatch(closeDrawer(parentId));
    };

   
        
//     const initialExperienceLevels = [
//         { name: 'junior', label: 'Junior (0-2 Years)', checked: false },
//         { name: 'midLevel', label: 'Mid Level (3-7 Years)', checked: false },
//         { name: 'senior', label: 'Senior (8+ Years)', checked: false },
//         // Add more experience levels as needed
//       ];

//       const [experienceLevels, setExperienceLevels] = useState(initialExperienceLevels);

//   const experienceCheckbox = (event) => {
//     const updatedExperienceLevels = experienceLevels.map((level) =>
//       level.name === event.target.name
//         ? { ...level, checked: event.target.checked }
//         : level
//     );
//     setExperienceLevels(updatedExperienceLevels);
//     console.log( event.target.name,event.target.checked )
//   };

  const skillCheckbox = (event) => {
    // const updatedSkills = selectSkill.map((level) =>
    //   level.name === event.target.name
    //     ? { ...level, checked: event.target.checked }
    //     : level
    // );
    // setSelectSkill(updatedSkills);
    // console.log( event.target.name,event.target.checked, {updatedSkills} )
    const updatedSkills = skill.map((s) =>
    s.name === event.target.name
      ? { ...s, checked: event.target.checked }
      : s
  );
  setSkill(updatedSkills);

  // Update selectedSkillsArray based on selected skills
  const updatedSelectedSkillsArray = updatedSkills
    .filter((s) => s.checked)
    .map((s) => s.name);

  setSelectSkill(updatedSelectedSkillsArray);
  }

 

  const handleFilterSubmit = () => {
    if (
        departmentData ||
        experienceLevels.length > 0 ||
        branchChange !== '' ||
        selectSkill.length > 0
      ) {
    const selectedFilters = {
        departmentData,
      experienceLevels,
      branchChange,
      selectSkill
   };
    // Collect the selected filter values into an object
    

    // Pass the selected filter values to the parent component
    onUpdateFilters(selectedFilters);
    dispatch(closeDrawer());
  }
  else {
    // Optionally, you can show a message or prevent the submission if no options are selected
    // For example, display a message to the user or prevent the submission
    console.log('Please select at least one filter option.');
  }
}

//   const filterData = (e) => {
//     e.preventDefault();
//     console.log({e})
//     props.onSubmit(selectSkill);
//     dispatch(closeDrawer());
//   };

//   const handleFilterApiCall = () => {
//     const selectFilterData = {
//         totalWorkExperience : experienceLevels
//     }
//   }

    // const { junior, midLevel, senior } = experience;

    // const [education, setEducation] = React.useState({
    //     junoir: false,
    //     midLevel: false,
    //     senior: false,
    // });

    // const educationCheckbox = (event) => {
    //     setEducation({
    //         ...education,
    //         [event.target.name]: event.target.checked,
    //     });
    // };

    // const { btech,mtech,mca  } = education;
    //   const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

    return (
        <>
            {/* <Box sx={{minWidth:{sm:'var(--width-drawer-sm)', md:'var(--width-drawer-sm)', lg:'var(--width-drawer-sm)'}}}> */}
            <Box sx={{width:{sm:'30rem'}}}>
                <Stack sx={{ backgroundColor: "#DBEAFE" }}>
                    <Box sx={{ p: '.5rem .1rem' }}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={10}>
                                <Box pl='1rem' display='flex'>
                                    <Typography variant="body1" color="text.primary" fontWeight="700" display="block" >Filters</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton aria-label="Close Drawer" component="label" onClick={handleDrawerClose} color='black'>
                                    <Close sx={{ color: 'black' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
                
                <Box sx={{ height: '80vh', overflow: 'auto', p: 3 }}>
                    {/* <Typography variant="subtitle2" pb={1}>Location</Typography> */}
                   <Grid container spacing={2}>
                        {/* <Grid item sm={12} xs={12} md={12} lg={12}>
                            <FormControl fullWidth>
                                <InputLabel id="countrySelectLabel">Country</InputLabel>
                                <Select
                                    labelId="countrySelectLabel"
                                    id="countrySelectBox"
                                    value={country}
                                    label="Country"
                                    onChange={changeCountrySelectValue}
                                >
                                    <MenuItem value={10}>Bharat</MenuItem>
                                    <MenuItem value={20}>Canada</MenuItem>
                                    <MenuItem value={30}>Japan</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}
                        {/* <Grid item sm={12} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="citySelectLabel">City</InputLabel>
                                <Select
                                    labelId="citySelectLabel"
                                    id="citySelectBox"
                                    value={city}
                                    label="City"
                                    onChange={changeCitySelectValue}
                                >
                                    <MenuItem value={10}>Noida</MenuItem>
                                    <MenuItem value={20}>Delhi</MenuItem>
                                    <MenuItem value={30}>Agra</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}
                       
                        <Grid item sm={12} xs={12}>
                            {/* <FormControl component="fieldset" variant="standard" >
                              
                                <Typography variant="subtitle2" >Experience</Typography>
                                <FormGroup>
                                {experienceLevels.map((level) => (
            <FormControlLabel
              key={level.name}
              control={
                <Checkbox
                  name={level.name}
                  checked={level.checked}
                  onChange={experienceCheckbox}
                />
              }
              label={level.label}
            />
          ))}
                                </FormGroup>

                            </FormControl> */}
                            <FormControl>

<Typography variant="subtitle2" color='textSecondary'>Experience</Typography>
{/* <RadioGroup
    aria-labelledby="experience-buttons-group-label"
    value={`${experienceLevels.min}-${experienceLevels.max}`}
    name="experience-radio-buttons-group"
    onChange={selectExperienceLevel}
>
    {experience.map((b) => { 
        //   console.log(b.id)
        return (
    <FormControlLabel key={b.id} value={b.id} control={<Radio />} label={b.label} />
    )})}
   </RadioGroup> */}
    <FormGroup>
                                {experience.map((s) => (
                                    <FormControlLabel key={s.id}
                                        control={
                                            <Checkbox checked={s.checked} onChange={selectExperienceLevel} id={s.id} />
                                        }
                                        label={s.label}
                                    />
                                    ))}
                                    </FormGroup>
</FormControl>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <FormControl component="fieldset" variant="standard">
                               <Typography variant="subtitle2" color='textSecondary'>Skills</Typography>
                                <FormGroup>
                                {skill.map((s) => (
                                    <FormControlLabel key={s.id}
                                        control={
                                            <Checkbox checked={s.checked} onChange={skillCheckbox} name={s.name} />
                                        }
                                        label={s.name}
                                    />
                                    ))}
                                    {/* <FormControlLabel
                                        control={
                                            <Checkbox checked={mca} onChange={educationCheckbox} name="mca" />
                                        }
                                        label="M.C.A"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={mtech} onChange={educationCheckbox} name="mtech" />
                                        }
                                        label="M.tech"
                                    /> */}
                                
                                </FormGroup>

                            </FormControl>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                        <Typography variant="subtitle2" pb={1} color='textSecondary'>Department</Typography>
                            <FormControl fullWidth>
                            <InputLabel id="departmentSelectLabel">Department</InputLabel>
                                <Select
                                    labelId="departmentSelectLabel"
                                    id="departmentSelectBox"
                                     value={departmentData}
                                    label="Department"
                                    onChange={changeDepartmentSelectValue}
                                >
                                    {department.map((d) => { 
                                        // console.log(d.department)
                                        return (
                                    <MenuItem key={d.id} value={d.id} >{d.department}</MenuItem>
                                    )})}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <FormControl>

                                <Typography variant="subtitle2" color='textSecondary'>Branch</Typography>
                                <RadioGroup
                                    aria-labelledby="branch-buttons-group-label"
                                    value={branchChange}
                                    name="branch-radio-buttons-group"
                                    onChange={handleBranchChange}
                                >
                                    {branch.map((b) => { 
                                        //  console.log(d.department)
                                        return (
                                    <FormControlLabel key={b.id} value={b.id} control={<Radio />} label={b.branchName} />
                                    )})}
                                    {/* <FormControlLabel value="high" control={<Radio />} label="High" />
                                    <FormControlLabel value="immediate" control={<Radio />} label="Immediate" /> */}
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                    </Grid>


                </Box>
                <Box sx={{ backgroundColor: 'white', width: '100%', position: 'absolute', bottom: '0', mt: '4rem', height: '4rem' }}>
                    <Stack sx={{ mx: '1rem', pt: '1rem' }} direction="row" spacing={1}>
                        <Button variant="contained" size="small" onClick={handleFilterSubmit} 
                       disabled={
                            departmentData.length == '' &&
                            !selectSkill.length &&
                            !experienceLevels.length  &&
                            !branchChange.length 
                          }
                        >SUBMIT</Button>
                        <Button variant="text" size="small" onClick={handleDrawerClose}>CANCEL</Button>
                    </Stack>
                </Box>
               
            </Box>
        </>
    )
}

export default FilterFindAssociateDrawer