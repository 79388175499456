import React from "react";
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';

function HelpdeskSkeleton() {
    return (
        <Box>
            <List>
                <Grid container spacing={2}>
                    <Grid item  xs={12} sm={12} sx={{}}>
                  {[1,2,3,4].map((data)=> <Card sx={{mb:2}}>
                    <CardHeader
                        title={
                            <Skeleton
                                animation="wave"
                                height={20}
                                width="80%"
                                style={{ marginBottom: 6 }}
                            />
                        }
                    />
                    </Card>)} 
                    </Grid>
                </Grid>
            </List>
        </Box>
    );

}

export default HelpdeskSkeleton;

