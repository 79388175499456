import React, {useState, useEffect} from "react";
import { TextField } from "@mui/material";

const FormTextField = (field) => {

    const [textField, setField] = useState(field.value);

    useEffect(() => {
        field.setTextField(textField)
    }, [textField])

    return (
    <TextField label={field.label} id={field.id} value={textField} onChange={(event) => {
        setField(event.target.value);
      }} required={field.required} />)

}

export default FormTextField;