import { createSlice } from '@reduxjs/toolkit';

const drawerSlice = createSlice({
  name: 'drawer',
  initialState: {
    isOpen: false,
    id: '',
    drawerData: {}
  },
  reducers: {
    openDrawer: (state, actions) => {
      state.isOpen = true;
      state.id = actions.payload;
    },
    closeDrawer: (state, actions) => {
      //console.log({state,actions})
      state.isOpen = false;
      state.id = actions.payload;
      //console.log(state,state.isOpen,'closeDrawer')
    },
    setDrawerData: (state, action) => {
      state.drawerData = action.payload;
    }
  },
});

export const { openDrawer, closeDrawer, setDrawerData } = drawerSlice.actions;

export default drawerSlice.reducer;
