import React from 'react'
import { Box, Grid, Avatar, TextField } from '@mui/material'
import SharedDialog from '../../features/dialogBox/dialogBox';
import CreatePostDialog from '../../component/dialogBox/CreatePostDialog';
import { openDialog, closeDialog } from "../../features/dialogBox/dialogBoxSlice";
import { useSelector, useDispatch } from 'react-redux';

const CreatePost = () => {

  const userData = useSelector((state) => state?.user?.userListName)

  const dispatch = useDispatch();
  const openDialogPublish = (id) => {
    dispatch(openDialog(id));
    // console.log(openDialog(id))
  }
  const closeDialogPublish = (id) => {
    dispatch(closeDialog(id));
  }

  return (
    <Box>
      <Box sx={{ '.MuiPaper-root .MuiCard-root': { mt: 0 } }}>
        <Box>

          <Grid container spacing={2}>
            <Grid item xs={2} sm={2} md={1.5} lg={1.5}>
              <Box sx={{ mt: 1 }}>
                <Avatar
                  src={userData.userAvatar}
                  alt={(userData?.userName)}
                  sx={{
                    backgroundColor: 'transparent !Important',
                    height: '45px',
                    boxShadow: 'none',
                    width: '45px',
                    '& .MuiAvatar-img': {
                      height: '45px',
                      width: '45px',
                      textAlign: 'center',
                      objectFit: 'cover',
                      color: 'transparent',
                      textIndent: '10000px'
                    }
                  }} />
              </Box>
            </Grid>
            <Grid item xs={10} sm={10} md={10.5} lg={10.5}>
              <Box sx={{ '.MuiTextField-root': { width: '100%' } }}>
                <TextField
                  id="outlined-basic"
                  placeholder="Ask your question here ?"
                  multiline
                  rows={1}
                  onClick={() => openDialogPublish('createDialog')}
                />
                {/* I'm assuming SharedDialog and CreatePostDialog are custom components */}
                {/* You can wrap your dialog components here */}
                <SharedDialog id="createDialog">
                  <CreatePostDialog id="createDialog" mode={'create'} />
                </SharedDialog>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default CreatePost