import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'menu',
    initialState: {
        menuSetting: [],
    },
    reducers: {
        setMenuSetting: (state, action) => {
            state.menuSetting = action.payload;
        },
    },
});

export const { setMenuSetting } = userSlice.actions;
export default userSlice.reducer;
