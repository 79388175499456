import React, { useEffect, useState } from 'react';
import { Box, Card, Grid, Typography } from '@mui/material';
import ApiService from '../../utils/ApiService';
import { useSelector} from 'react-redux';
import Advertisment from '../FeedPost.js/Advertisment';
import CreatePost from '../FeedPost.js/CreatePost';
import SharedDialog from '../../features/dialogBox/dialogBox';
import CreatePostDialog from '../dialogBox/CreatePostDialog';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import HelpdeskSkeleton from './HelpdeskSkeleton';

const Helpdesk = ({ setFlag }) => {
  const userData = useSelector((state) => state?.user?.userListName)
  const [viewTicket, setViewTicket] = React.useState(false);
  const [faq, setFAQ] = useState([])

  useEffect(() => {
    if (userData?.userId !== undefined) {
      const apiParams = {};
      if (userData?.userId &&viewTicket) {
        apiParams.tickertRaisedBy = userData.userId;
      }
      ApiService.getHelpdesk('tickets',apiParams)
        .then((res) => {
          let data = res.data.data;
          setFAQ(data)
        })
    }
  }, [userData , viewTicket]);
  return (
    <Box sx={{ minHeight: 'calc(95vh - 90px)' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={setFlag !== false ? 8 : 12} sx={{}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CreatePost />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <div>
                <Box sx={{display:"flex", justifyContent:"space-between"}}>
                  {viewTicket ?
                  <Typography pb={1.5} variant='h6' display='flex'>Tickets raised by {userData?.userName} {userData?.lastName}</Typography>
                  :<Typography pb={1.5} variant='h6' display='flex'>Frequently Asked Questions</Typography>}
                 {viewTicket ? <Button onClick={()=>setViewTicket(false)}>FAQs</Button>:
                 <Button onClick={()=>setViewTicket(true)}>View Tickets</Button>}
                </Box>
                {faq.length? faq.map((data) => <Accordion key={data.id} sx={{ mb: 2 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ py: 2 }}
                  >
                    <Typography variant="body1" className='overflow-text-Announcement' fontWeight={500} >   {data.subject}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {data.description}
                  </AccordionDetails>
                </Accordion>):
                <HelpdeskSkeleton />}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className='height-2' xs={12} sm={4}>
          <Grid container spacing={2}>
            {/* {isFeed && */}
            {/* <Grid item xs={12} sx={{ mb: 2 }}>
              <AnnouncementComponent notice={notification} />
            </Grid> */}
            {/* } */}


            <Grid item xs={12}>
              <Box>
                <Card>
                  <Advertisment />
                </Card>
              </Box>
            </Grid>

          </Grid>
        </Grid>
        {/* // } */}
      </Grid>
      <SharedDialog id="editDialog">
        <CreatePostDialog id="editDialog" mode={'edit'} />
      </SharedDialog>
    </Box>
  );
};

export default Helpdesk;
