import { createSlice } from '@reduxjs/toolkit';

const shareInfoSlice = createSlice({
    name: 'shareInfo',
    initialState: {
        info: {},
    },
    reducers: {
        setSharedInfo: (state, action) => {
            if(!(Symbol.iterator in Object(state.info))){
                state.info = {};
            }
            state.info = {...state.info,...action.payload};
        },
    },
});

export const { setSharedInfo } = shareInfoSlice.actions;
export default shareInfoSlice.reducer;
