import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { Divider, Button, Alert, FormControl, InputLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import CardHeader from '@mui/material/CardHeader';
import { closeDialog } from "../../features/dialogBox/dialogBoxSlice";
import { openImage } from '../../features/uploadPic/ImageSlice';
import { setFormData, setFormUpdate } from '../../features/commonDataSlice/postSlice';
import { useSelector, useDispatch } from 'react-redux';
import ImageIcon from '@mui/icons-material/Image';
import ApiService from '../../utils/ApiService';
import ConfirmDialogBox from '../../features/confirmDialogBox/confirmDialogBox';
import { openConfirmDialog } from '../../features/confirmDialogBox/confirmDialogBoxSlice';
import { getAppToken } from '../../_helper/secureToken';
// import { openSnackbar, closeSnackbar } from '../../features/snackbar/snackbarSlice';
// import DynamicSnackbar from '../../features/snackbar/Snackbar';
import { setData } from "../../features/dialogBox/dialogBoxSlice";
import Textarea from "../forms/Textarea";

const CreatePostDialog = ({ id, mode }) => {
  // const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });
  const [deletedId, setDeletedId] = useState([]);
  const userData = useSelector((state) => state?.user?.userListName)
  const postData = useSelector((state) => state?.dialog?.data);
  const [image, setImage] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const fileType = useRef(null);
  const deleteBtn = useRef(null);
  const [showFileError, setShowFileError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [disabelPost, setDisablePost] = useState(false);

  const handleClose = () => {
    setShowError(false);
    setShowFileError(false);
  };

  useEffect(() => {
    setDisablePost(showError || showFileError);
  }, [showError, showFileError]);

  useEffect(() => {
    console.log("Change testing", { postData });

    return () => {
      dispatch(setData({})); // Dispatch an action to set dialog.data to null when the component unmounts
    };
  }, []);

  const dispatch = useDispatch();
  const openDialogPublish = (id) => {
    dispatch(openConfirmDialog(id));
    // console.log(openDialog(id))
  }

  const handlePostDialogClose = () => {
    dispatch(closeDialog(id));
  };

  const handlePhotoClick = () => {
    dispatch(openImage());
  };

  const handleBothClicks = () => {
    handlePostDialogClose();
    handlePostButtonClick();
    handleConfirmationClick();
    handleConfirmationMessage();
  };

  const handleConfirmationClick = () => {
    setOpen(true);
  };


  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [postBody, setBody] = useState(postData.postBody ?? "");
  const [fileImage, setFileImage] = useState(postData?.album?.images.map((d) => {
    return d.image
  }) ?? []);
  // let fileImage = [];
  let fileImageForm = [];

  const handleConfirmationMessage = () => {


  };

  // ---------------edit post -----------------


  const handlePostButtonClick = () => {
    let formData = new FormData();
    formData['app']=getAppToken()
    formData['subject']=subject
    formData['description']=description

    if (subject) {
      formData.append('subject', subject);
    }
    if (description) {
    formData.append('description', description);
    }
        ApiService.postHelpdesk('tickets', formData)
          .then((res) => {
            let data = res.data.data;
            if (data) {
              dispatch(setFormData(data));
              // dispatch(openSnackbar({ dataLoad: true, message: "Your Question  Successfully Added", severity: "info" }));
              // console.log('aaaa',)
            }
          })
          .catch((error) => {
            // dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
          });
    
  };


  const [open, setOpen] = React.useState(false);
  return (
    <Box sx={{ width: '-webkit-fill-available' }}>
      <Box sx={{ p: 1, backgroundColor: '#00aeef36' }}>
        <Stack>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={10}>
              <Box display='flex' sx={{ flexDirection: 'column', ml: '7px' }}>
                <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block">Ask Questions</Typography>
              </Box>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton aria-label="upload picture" component="label" color='var(--color-black)' onClick={() => handlePostDialogClose()}>
                <CloseIcon sx={{ color: 'var(--color-black)' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <Divider />
      <Box className='height-dialog' sx={{ p: 'var(--padding-all-1)' }}>
        <Box sx={{ width: '100%' }}>
          <CardHeader sx={{ p: 0, my: 0 }}
            avatar={
              <Avatar
                src={userData.userAvatar}
                sx={{
                  height: '40px',
                  boxShadow: 'none',
                  width: '40px',
                  '& .MuiAvatar-img': {
                    height: '40px',
                    width: '40px',
                    objectFit: 'contain',
                  },
                }}
              />
            }
            title={
              <Typography variant='defineSubtitle2' color='primary.headingDark'>{userData['userName'] + (userData['middleName'] && userData['middleName'] != '--' ? ' ' + userData['middleName'] : ' ') + (userData['lastName'] ? ' ' + userData['lastName'] : '')}</Typography>}
          />
        </Box>
        <Box sx={{ mt: 1 }}>
          <Stack>
            <Grid container direction="row" alignItems="center" id='postImage' sx={{ maxHeight: "20rem", overflow: "auto" }}>
              <Grid item xs={12}>
                <Box sx={{ maxWidth: '100%', '& textarea:focus-visible': { border: '0px solid', outline: 'none' }, mt:2 }}>
                    <InputLabel htmlFor="my-input">Subject </InputLabel>
                    <Textarea label={'Enter subject '} setTextField={setSubject} value={subject} sx={{ width: '100%', border: '0px solid', resize: 'none' }} />
                    <InputLabel htmlFor="my-input">Description </InputLabel>
                    <Textarea label={"Enter Description"} setTextField={setDescription} value={description} sx={{ width: '100%', border: '0px solid', resize: 'none' }}/>
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <Button variant="contained" onClick={handleBothClicks} disabled={disabelPost}>ASK NOW</Button>
      </Box>
      <ConfirmDialogBox
        id='ConfirmDialog'
        title="Confirmation Dialog"
        body={<p>Are you sure you want to proceed?</p>}
        titleOne="Cancel"
        titleTwo="Confirm"
      >
      </ConfirmDialogBox>
      {/* <DynamicSnackbar /> */}
    </Box >
  );
};

export default CreatePostDialog;
