import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, CardHeader, Grid, IconButton, Link, Typography } from '@mui/material'
// import MyConnectLayout from '../component/CommonPage/MyConnectLayout';
// import MyConnectLayout from '../CommonPage/MyConnectLayout'
import ApiService from '../../utils/ApiService';
import { useSelector, useDispatch } from 'react-redux';
import PostSkeleton from '../../component/CommonPage/PostSkeleton';
import BreadcrumbsComponent from '../../component/Breadcrumbs';
import { Share, Work } from '@mui/icons-material';
import CampaignIcon from '@mui/icons-material/Campaign';

import AnnouncementComponent from '../FeedPost.js/AnnouncementComponent';

const News = ({ setFlag }) => {
  const dispatch = useDispatch();
  const [FeedAnnouncements, setFeedAnnouncements] = React.useState([]);
  const [snackBar, setSnackBar] = useState({ dataLoad: false, message: '' });
  const userData = useSelector((state) => state?.user?.userListName)
  const showPostData = useSelector((state) => state.formData.formToSend);
  const notification = [{ id: 1, title: "Message from Employer", textBoard: "Hi there! Your FNF is completed and created to your bank account", created_at: "" },
  { id: 1, title: "Message from Employer", textBoard: "Hi there! Your FNF is completed and created to your bank account", created_at: "" },
  { id: 1, title: "Message from Employer", textBoard: "Hi there! Your FNF is completed and created to your bank account", created_at: "" }]
  const indivisualAPI = 'News';
  const jobs = [{ id: 1, title: "Frontend Developer", textBoard: "We requires candidate who have knowledge of php OOP's concept and hands on experience in Laravel framework", created_at: "2024-01-03T09:47:20.000000Z" },
  { id: 1, title: "Laravel Developer", textBoard: "We requires candidate who have knowledge of php OOP's concept and hands on experience in Laravel framework", created_at: "2024-01-03T09:47:20.000000Z" },
  { id: 1, title: "Fullstack Developer", textBoard: "We requires candidate who have knowledge of php OOP's concept and hands on experience in Laravel framework", created_at: "2024-01-03T09:47:20.000000Z" }]
  const callApi = () => {
    ApiService.getApi(indivisualAPI)
      .then((res) => {
        let data = res.data.data;
        if (data) {
          setFeedAnnouncements(data);
        }
      })
      .catch((error) => {
        setSnackBar({ dataLoad: true, message: error.message, severity: 'error' });
      });
  }
  function formatDate(dateString) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  }
  // const FeedAnnouncements = [
  //   { id: 1,title:"Mastech Digital to Acquire Leading Semiconductor Design Services Provider, InSemi ", date:"11 JAN 2024",content:"Mastech Digital today announced a definitive agreement to acquire InSemi, a leading semiconductor design and embedded services provider.", shareUrl:"" },
  //   { id: 2,title:"Financial information for the Third Quarter ended December 31, 2023    ", date:"11 JAN 2024",content:"The results for the Third Quarter ended December 31, 2023 have been announced.", shareUrl:"" },
  //   { id: 3,title:"Mastech Digital to Announce Third Quarter Results on January 11, 2024 ", date:"28 DEC 2023",content:"Mastech Digital will announce results for the third quarter ended December 31, 2023 on Thursday, January 11, 2024.", shareUrl:"" },
  //   { id: 4,title:"Mastech Digital to Harmonize the Systems of LKQ Europe    ", date:"21 DEC 2023",content:"Mastech Digital today announced a 5-year collaboration with LKQ Europe, one of the leading distributors of automotive aftermarket parts for cars, commercial vans, and industrial vehicles in Europe.", shareUrl:"" },
  //   { id: 5,title:"Mastech Digital Inaugurates New Development Center in Nagpur    ", date:"16 DEC 2023",content:"Mastech Digital today announced the inauguration of a new state-of-the-art development center (DC) at the Mihan-SEZ in Nagpur, Maharashtra.", shareUrl:"" },
  //   { id: 6,title:"Mastech Digital appoints Jayesh Sanghrajka as Chief Financial Officer", date:"11 DEC 2023",content:"Mastech Digital announced today that its Board of Directors has appointed Jayesh Sanghrajka as the Chief Financial Officer (“CFO”) and Key Managerial Personnel of the Company with effect from April 1, 2024.", shareUrl:"" },

  // ]
  // useEffect(() => {
  //   callApi();
  // }, [userData]);

  useEffect(() => {
    callApi();
  }, []);

  return (
    <Box>
      <Box>
        {/* <Typography pb={1.5} variant='h6' display='flex'><BreadcrumbsComponent />&nbsp;/ Announcements</Typography> */}
        <Typography pb={1.5} variant='h6' display='flex'>News</Typography>

      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={setFlag !== false ? 8 : 12} >
            <Grid container spacing={2}>

              <Grid item xs={12} >
                {
                  FeedAnnouncements.length ? FeedAnnouncements.map((data) =>
                    <Card sx={{ mb: 3, borderLeft: "4px solid rgb(60, 130, 245) " }}>
                      <CardHeader
                        title={<Link className='newsHeadingText' href={data.url} target="_blank">{data.title}</Link>}
                        sx={{ p: 0 }}
                        subheader={formatDate(data.created_at)}
                        action={
                          <IconButton aria-label="settings" sx={{ color: "rgb(60, 130, 245)" }}>
                            <Link href={data.url} target="_blank">
                              <Share /></Link>
                          </IconButton>
                        }
                      />
                      <CardContent sx={{ p: 0, pt: 2 }}>
                        <Typography variant='h7'>{data.description}</Typography>
                      </CardContent>
                    </Card>
                  )


                    : <PostSkeleton />
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item className='height-2' xs={12} sm={4}>
            <Grid container spacing={2}>
              {/* {isFeed && */}
              <Grid item xs={12}>
                <AnnouncementComponent notice={FeedAnnouncements} title={"Popular News"} icon={<CampaignIcon fontSize="small" />} redirectUrl={"/news"} />
              </Grid>
              {/* } */}
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <AnnouncementComponent notice={jobs} title={"Latest Jobs"} icon={<Work fontSize="small" />} redirectUrl={"/career"} />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* {
        FeedAnnouncements.length ?
        <MyConnectLayout posts={FeedAnnouncements} snackBar={snackBar} isHeader={false} setPostsList={setFeedAnnouncements} indivisualAPI={indivisualAPI} formTransform={formLeader} reactPost={'reactPostPages'} />
        : <PostSkeleton />
      } */}
    </Box>
  )
}

export default News