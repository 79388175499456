import React, { useEffect, useState } from 'react';
// import { removeUserToken } from '../_helper/secureToken';
// import { getCallback, removeUserToken, logoutHandler } from 'auth-component'
import {logoutHandler} from "auth-component"
import { Navigate, useNavigate } from 'react-router-dom';

function Logout(props) {
    let navigate=useNavigate()
    useEffect(function () {
        navigate("/login")
    }, [])
    logoutHandler();
}

export default Logout;