import React, {useState, useEffect} from 'react'
import ApiService from '../../../utils/ApiService';
import {
    Card, Typography, Grid, Box, styled, Chip, MoreVert, Divider, Tooltip, RadioGroup, Radio, FormLabel,
    MenuItem, FormControl, InputLabel, Checkbox, Select, FormGroup, Stack, FilterList, IconButton, PeopleOutline, FormControlLabel,
    WatchLaterOutlined, LocationOnOutlined, ArrowForwardOutlined, Button, Close
} from '../../../utils/AllImportsHelper'
import { useDispatch } from 'react-redux';
import { closeDrawer } from '../../../features/drawers/drawerSlice';

const FilterReferralJobDrawer = ({onUpdateFilters,parentId}) => {
    const [country, setCountry] = React.useState([]);
    const [countryData, setCountryData] = React.useState('');
    const [city, setCity] = React.useState([]);
    const [cityData, setCityData] = React.useState('');
    const [department, setDepartment] = React.useState([]);
    const [departmentData, setDepartmentData] = useState('');
    const [priority, setPriority] = React.useState([]);
    const [jobPriorityValue, setJobPriorityValue] = useState('');
    const educationData = [
        {  label: 'B.tech', id:'btech', name:'btech' },
        {  label: 'M.tech', id:'mtech', name:'mtech' },
        {  label: 'M.C.A', id:'mca', name:'mca'},
        {  label: 'B.C.A', id:'bca', name:'bca' },
        // Add more experience levels as needed
      ];
    const [education, setEducation] = React.useState(educationData);
    const [selectEducation, setSelectEducation] = React.useState([]);
    const [experience, setExperience] = React.useState(
        [
            { name: 'junior', label: 'Junior (0-2 Years)', id:'0-2', checked: false },
            { name: 'midLevel', label: 'Mid Level (3-7 Years)', id:'3-7', checked: false },
            { name: 'senior', label: 'Senior (8+ Years)', id:'8-50', checked: false },
            ]
    );
    const [experienceLevels, setExperienceLevels] = useState([]);
    

    useEffect(() => {
        ApiService.get('orgDepartment').then(department => {
            setDepartment(department.data.data)
                //  console.log(department.data.data)
              })

              ApiService.get('getAllCity').then(city => {
                setCity(city.data.data)
                    //  console.log(city.data.data)
                  })

                  ApiService.get('getAllCountry').then(country => {
                    setCountry(country.data.data)
                        //  console.log(country.data.data)
                      })

                      ApiService.get('jobPriority').then(priority => {
                        setPriority(priority.data.data)
                            //  console.log(priority.data.data)
                          })         
        }, []);

        const changeCountrySelectValue = (event) => {
            setCountryData(event.target.value);
            console.log(event.target.value)
        };
    
        
        const changeCitySelectValue = (event) => {
            setCityData(event.target.value);
        };
    
        const changeDepartmentSelectValue = (event) => {
            setDepartmentData(event.target.value);
            // console.log(event.target.value,{event})
            };
    
            const handleJobPriority = (event) => {
                setJobPriorityValue(event.target.value);
                // console.log(event.target.value,{event})
            };

    const dispatch = useDispatch();
    const handleDrawerClose = () => {
        dispatch(closeDrawer(parentId));
    };


    

      const educationCheckbox = (event) => {
        const educationSelection = education.map((s) =>
        s.name === event.target.name
          ? { ...s, checked: event.target.checked }
          : s
      );
      setEducation(educationSelection);
    
      // Update selectedSkillsArray based on selected skills
      const updatedEducationSelectionArray = educationSelection
        .filter((s) => s.checked)
        .map((s) => s.name);
    
      setSelectEducation(updatedEducationSelectionArray);
      }

    //   const experience = [
    //     { name: 'junior', label: 'Junior (0-2 Years)', id:'0-2' },
    //     { name: 'midLevel', label: 'Mid Level (3-7 Years)', id:'3-7' },
    //     { name: 'senior', label: 'Senior (8+ Years)', id:'8-50' },
    //     // Add more experience levels as needed
    //   ];

      const selectExperienceLevel = (event) => {
        const updatedExperienceLevel = experience.map((s) =>
          s.id === event.target.id ? { ...s, checked: event.target.checked } : s
        );
        setExperience(updatedExperienceLevel);
    
        // Update selectedExperienceLevels based on selected experience levels
        const updatedSelectedExpArray = updatedExperienceLevel
          .filter((s) => s.checked)
          .map((s) => s.id);
    
        setExperienceLevels(updatedSelectedExpArray);
      };

      const handleFilterSubmit = () => {
        // Collect the selected filter values into an object
        if (
            departmentData ||
            selectEducation.length > 0 ||
            experienceLevels.length > 0 ||
            jobPriorityValue !== '' ||
            countryData ||
            cityData
          ) {
        const selectedFilters = {
            departmentData,
          experienceLevels,
          selectEducation,
          jobPriorityValue,
          countryData,
          cityData
       };
    
        // Pass the selected filter values to the parent component
        onUpdateFilters(selectedFilters);
        console.log(selectedFilters)
        dispatch(closeDrawer());
    }
    else {
        // Optionally, you can show a message or prevent the submission if no options are selected
        // For example, display a message to the user or prevent the submission
        console.log('Please select at least one filter option.');
      }
      };

    return (
        <>
            {/* <Box sx={{minWidth:{sm:'var(--width-drawer-sm)', md:'var(--width-drawer-sm)', lg:'var(--width-drawer-sm)'}}}> */}
            <Box sx={{width:{sm:'30rem'}}}>
                <Stack sx={{ backgroundColor: "#DBEAFE" }}>
                    <Box sx={{ p: '.5rem .1rem' }}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={10}>
                                <Box pl='1rem' display='flex'>
                                    <Typography variant="body1" color="text.primary" fontWeight="700" display="block" >Filters</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton aria-label="Close Drawer" component="label" onClick={handleDrawerClose} color='black'>
                                    <Close sx={{ color: 'black' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
                <Box sx={{ height: '80vh', overflow: 'auto', p: 3 }}>
                    <Typography variant="subtitle2" color='textSecondary' pb={1}>Location</Typography>
                   <Grid container spacing={2}>
                        <Grid item sm={12} xs={12} md={12} lg={12}>
                            <FormControl fullWidth>
                                <InputLabel id="countrySelectLabel">Country</InputLabel>
                                <Select
                                    labelId="countrySelectLabel"
                                    id="countrySelectBox"
                                    value={countryData}
                                    label="Country"
                                    onChange={changeCountrySelectValue}
                                >
                                     {country.map((c) => { 
                                        // console.log(d.department)
                                        return (
                                    <MenuItem key={c.countryId} value={c.country} >{c.country}</MenuItem>
                                    )})}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="citySelectLabel">City</InputLabel>
                                <Select
                                    labelId="citySelectLabel"
                                    id="citySelectBox"
                                    value={cityData}
                                    label="City"
                                    onChange={changeCitySelectValue}
                                >
                                  {city.map((city) => { 
                                        return (
                                    <MenuItem key={city.id} value={city.name} >{city.name}</MenuItem>
                                    )})}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <FormControl>
                                {/* <FormLabel id="jobPriorityLabel">Job Priority</FormLabel> */}
                                <Typography variant="subtitle2" color='textSecondary'>Job Priority</Typography>
                                <RadioGroup
                                    aria-labelledby="job-priority-buttons-group-label"
                                    value={jobPriorityValue}
                                    onChange={handleJobPriority}
                                    name="job-priority-radio-buttons-group"
                                >
                                   {priority.map((priority) => { 
                                        return (
                                    <FormControlLabel key={priority.id} value={priority.id} control={<Radio />} label={priority.name} />
                                    )})}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            
 <FormControl component="fieldset" variant="standard">

<Typography variant="subtitle2" color='textSecondary'>Experience</Typography>
{/* <RadioGroup
    aria-labelledby="experience-buttons-group-label"
    value={`${experienceLevels.min}-${experienceLevels.max}`}
    name="experience-radio-buttons-group"
    onChange={selectExperienceLevel}
>
    {experience.map((exp) => { 
        //   console.log(exp.id)
        return (
    <FormControlLabel key={exp.id} value={exp.id} control={<Radio />} label={exp.label} />
    )})}
    </RadioGroup> */}
    <FormGroup>
                                {experience.map((s) => (
                                    <FormControlLabel key={s.id}
                                        control={
                                            <Checkbox checked={s.checked} onChange={selectExperienceLevel} id={s.id} />
                                        }
                                        label={s.label}
                                    />
                                    ))}
                                    </FormGroup>
</FormControl>
                           
                        </Grid>
                        <Grid item sm={12} xs={12}>
                            <FormControl component="fieldset" variant="standard">
                                {/* <FormLabel component="legend">Education</FormLabel> */}
                                <Typography variant="subtitle2" color='textSecondary'>Education</Typography>
                                <FormGroup>
                                {education.map((e) => (
                                    <FormControlLabel key={e.id}
                                        control={
                                            <Checkbox checked={e.checked} onChange={educationCheckbox} name={e.name} />
                                        }
                                        label={e.label}
                                    />
                                    ))}
                                </FormGroup>

                            </FormControl>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                        <Typography variant="subtitle2" color='textSecondary' pb={1}>Department</Typography>
                            <FormControl fullWidth>
                            <InputLabel id="departmentSelectLabel">Department</InputLabel>
                                <Select
                                    labelId="departmentSelectLabel"
                                    id="departmentSelectBox"
                                    value={departmentData}
                                    label="Department"
                                    onChange={changeDepartmentSelectValue}
                                >
                                    {department.map((d) => { 
                                        return (
                                    <MenuItem key={d.id} value={d.department} >{d.department}</MenuItem>
                                    )})}
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>


                </Box>
                <Box sx={{ backgroundColor: 'white', width: '100%', position: 'absolute', bottom: '0', mt: '4rem', height: '4rem' }}>
                    <Stack sx={{ mx: '1rem', pt: '1rem' }} direction="row" spacing={1}>
                        <Button variant="contained" size="small" onClick={handleFilterSubmit} 
                        disabled={
                            departmentData.length == '' &&
                            !selectEducation.length &&
                            !experienceLevels.length &&
                            !jobPriorityValue.length &&
                            countryData.length == '' &&
                            cityData.length == ''
                          }
                          >SUBMIT</Button>
                        <Button variant="text" size="small" onClick={handleDrawerClose}>CANCEL</Button>
                    </Stack>
                </Box>
            </Box>
        </>
    )
}

export default FilterReferralJobDrawer