import React from 'react'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Box, Container } from '@mui/material';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import image2 from '../assets/images/welcomePage.png';
import image3 from "../assets/images/thplogo.svg"
import image4 from '../assets/images/image4.png';
import Footer from '../layouts/footer/Footer';
import image1 from "../assets/images/image1.png"
import { Link, useNavigate } from 'react-router-dom'
// import { setSnackBar } from '../features/snakebar/snakebar';
import { useDispatch } from 'react-redux';
import { loginHandler } from "auth-component"


const Img = styled('img')({
  margin: 'auto',
});
const Login = () => {
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = React.useState(false);
  const [emailErr, setEmailErr] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [passErr, setPassErr] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const [value, setValue] = React.useState(0);
  const navigate = useNavigate()
  // const ref = React.useRef(null);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  function onChangeHander(e) {
    console.debug("emailFeilds",e.target.name)
    if (e.target.name == "email") {
      setEmail(e.target.value)
    }
    if (e.target.name == "password") {
      setPassword(e.target.value)
    }
  }
 function loginClickHandler(e) {
  console.debug("loginClickHandler",email)
    e.preventDefault();
    e.stopPropagation();
    let postData = {};
    if (mailformat.test(email)) {
      postData.email = email;
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }
    if (password) {
      postData.password = password;
      setPassErr(false);
    } else {
      setPassErr(true);
    }
    console.debug("login:email",email)
    console.debug("login:password",password)

    if (email && password) {
      loginHandler(postData).then(r => {
        if (r.error) {
          setErrorMsg(<p style={{ color: "red" }}>These credentials do not match our records.</p>)
        }
        else {
          // dispatch(setSnackBar({ dataLoad: true, message: "Login successfull", severity: "success" }))
          if (r != null && r.redirectUrl) {
            window.location.assign(r.redirectUrl)
          } else {
            window.location.assign("/")
          }
        }
      }).catch(e => {
        // dispatch(setSnackBar({ dataLoad: true, message: e.message, severity: "error" }))
        console.debug("ssoLoginHandler : response : ", e);
      });
    }
    else{
      setEmailErr(<p>"Fields are mandatory"</p>)
    }
  }
  return (
    <Box
      component="div"
      sx={{
        // position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundImage: `url(${image1})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Box sx={{ height: "94vh", alignItems: "center", justifyContent: "center", display: "flex", alignSelf: "center" }}>
        <Container fluid='true' sx={{ maxHeight: '550px', px: { xs: 1, sm: 6, md: 5 } }}>
          <Card sx={{ width: '100%', alignItems: 'center', display: 'flex', p: 0, height: "100%" }}>
            <Box sx={{ position: "relative", width: '100%', }}>
              <Img alt="complex" sx={{ width: "100%" }} src={image2} />
              <Box sx={{ position: "absolute", zIndex: 5, top: 0, m: 2, width: "100%" }}>
                <Img alt="complex" src={image3} />
                <Box sx={{ marginLeft: 5, color: "white", marginTop: 3 }}  >
                  <Typography variant='h3'>The Higher Pitch</Typography>
                  <Typography variant="body1">We are a performance marketing company</Typography>
                  <Typography variant="body1">  that specializes in  creating digital experience for enterprises.</Typography>
                </Box>
              </Box>
            </Box>
            <Box className='center'>
              <Box className='padding'>
                <Img alt="complex" src={image4} sx={{ height: "66px", width: "120px" }} />
                <Typography variant="h4" color="text.primary" sx={{ pb: 6, pt: 4 }} className="center">
                  Login to your account
                </Typography>
                {/* <div className='center'> */}
                <FormControl sx={{ m: 'auto', width: '65%' }}>
                  <TextField id="outlined-basic"
                    type='email'
                    name="email"
                    onChange={(e)=>onChangeHander(e)}
                    label="Email" variant="outlined" />
                </FormControl>
                <FormControl sx={{ m: 2, width: '65%', mb: 1 }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    name="password"
                    // error={emailErr}
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e)=>onChangeHander(e)}


                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <Box display={"flex"}>
                <Typography variant="body2" align="right" sx={{ mr: '17%', pb: 3, textDecoration: 'none' }}>
                  <Link to='/forgot' style={{ color: '#1976d2' }}>Forgot password?</Link>
                </Typography>
                <Typography variant="body2" align="right" sx={{ mr: '17%', pb: 3, textDecoration: 'none' }}>
                  <Link to='/reset' style={{ color: '#1976d2' }}>Reset password?</Link>
                </Typography>
                </Box>
                <Button variant="contained" size="large" sx={{ width: '65%', backgroundColor: "rgba(37, 99, 235, 1)" }} onClick={(e) => loginClickHandler(e)}>
                  LOG IN
                  {/* LOG IN  */}
                </Button>
                <Box sx={{ width: "65%", m: "auto", pb: 1, mt: 3 }}>
                  <Typography variant="body2" sx={{ textDecoration: 'none' }}>  New user?
                    <Link to='/register' style={{ color: '#1976d2' }}> Create an account</Link>
                  </Typography>
                </Box>
              </Box>
            </Box>

          </Card>
        </Container>
      </Box>
      <Footer />
    </Box>
  )
}

export default Login