// visitedLinksSlice.js
import { createSlice } from "@reduxjs/toolkit";

const BreadcrumbsSlice = createSlice({
  name: "visitedLinks",
  initialState: {
    links: [],
  },
  reducers: {
    addLink: (state, action) => {
      state.links.push(action.payload);
    },
    clearLinks: (state) => {
      state.links = [];
    },
  },
});

export const { addLink, clearLinks } = BreadcrumbsSlice.actions;

export default BreadcrumbsSlice.reducer;
