import React from 'react';
import { Box, Typography, CardContent, Card, Avatar, CardHeader } from '../../utils/AllImportsHelper';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Grid, IconButton } from '@mui/material';
import DOMPurify from 'dompurify';
// import {   } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Announcement } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentRoute } from '../../features/sidebarRouting/sidebarSelectionSlice';
import { useEffect } from 'react';
import encriptionHelper from '../../utils/encriptionHelper';

function formatDate(dateString) {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const date = new Date(dateString);
  return date.toLocaleString('en-US', options);
}

const AnnouncementComponent = ({ notice,post , title,icon, feedPostData ,redirectUrl}) => {

  console.log("notice",{notice});
  const indivisualAPI = 'FeedAnnouncements';

  // useEffect(() => {
  //   console.log("notice",{notice})
  // }, [notice])

  const dispatch = useDispatch();
  const feedPostName = useSelector(state => state.routing.currentRoute);

  const getBackgroundColor = (index) => {
    switch (index % 4) {
      case 0:
        return "#DDFCE6";
      case 1:
        return "#FBE7F3";
      case 2:
        return "#DBEAFE";
      case 3:
        return "#FFEDD6";
      default:
        return "#DDFCE6"; // Default color
    }
  };

  const navigate = useNavigate();
  const handleAddManually = (url) => {
    navigate(url);
    dispatch(setCurrentRoute(url));
  };

  const navigateMove = useNavigate();

  const encodeUrl = () => {
    const queryStringObject = {
        indivisualAPI: indivisualAPI,
        reactPost: 'reactPostPages',
        feedPostName: feedPostName,
    };
    const encodedQueryString = encriptionHelper().encodeAndEncryptURLParams(queryStringObject);
    console.log({post})
    return `/feed/${post.id}?query=${encodedQueryString}`;
  }

  const handleMoveManually = (post) => {
    navigateMove(encodeUrl(),{state: { feedPostData: {"comments":post.comment,"userAvatar": post?.user?.userAvatar,"created_at":post.dateOfCreation,"userName": post?.user?.userName,"postBody":post.textBoard,"title":post.title,'image': post.noticeImage,...post},feedPostName: feedPostName, indivisualAPI: indivisualAPI }});
  };

  return (
    <Box>
      <Card sx={{ height: '100%', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', p: 0}}>
        <Grid container rowSpacing={1}   sx={{ p: 2, pb: 0, "!important": true ,display: 'flex',alignItems:'center'}}>
          <Grid item  xs={2} sm={2} md={2} lg={1.5}>
            <Box>
              <Avatar>
               {icon}
              </Avatar>
            </Box>
          </Grid>
          <Grid item xs={7} sm={8} md={7} lg={7.5}>
            <Box>
              <Typography variant="h6">{title}</Typography>
            </Box>
          </Grid>
          <Grid item xs={2} sm={2} md={3} lg={3} sx={{display:'flex',justifyContent:'flex-end'}}>
            <Box sx={{}}>
              <IconButton aria-label="delete" onClick={()=>handleAddManually(redirectUrl)}>
                <ArrowOutwardIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <CardContent>
        {  console.debug("notice",notice)}
          <Box>
            {notice.slice(0,2).map((item, index) => (
              <Card key={index} className="" sx={{ backgroundColor: getBackgroundColor(index), mb: 2,cursor:'pointer'}} onClick={() => handleMoveManually(item)}>
                <Box className="overflow-text-Announcement">
                  <Typography variant='body2' fontWeight='bold'gutterBottom>{item.title}</Typography>
                </Box>
                <Box>
                  <Typography variant='caption'display="block" gutterBottom>{formatDate(item.created_at)}</Typography>
                </Box>
                <Box className="overflow-text-Announcement">
                  <Typography variant='body2' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.textBoard) }}></Typography>
                </Box>
              </Card>
            ))}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AnnouncementComponent;
