import React from 'react'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Box, Checkbox, Container, FormControlLabel, List, ListItem } from '@mui/material';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import image2 from '../assets/images/loginBanner.png';
import image3 from "../assets/images/thplogo.svg"
import image4 from '../assets/images/mastechLogo.svg';
import Footer from '../layouts/footer/Footer';
import image1 from "../assets/images/image1.png"
import { Link, useNavigate } from 'react-router-dom'
const Img = styled('img')({
  margin: 'auto',
  backgroundSize:"cover",
  height:"100%"
});
const RegisterMastech = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const [value, setValue] = React.useState(0);
  const navigate = useNavigate()
  // const ref = React.useRef(null);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        height: '100%',
        backgroundImage: `url(${image1})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Box sx={{ height: "94vh", alignItems: "center", justifyContent: "center", display: "flex", alignSelf: "center" }}>
        <Container fluid='true' sx={{ maxHeight: '720px', px: { xs: 1, sm: 6, md: 5 } }}>
          <Card sx={{ width:"100%",alignItems: 'center', display: 'flex', p: 0, height: "720px" }}>
            <Box sx={{ width:"100%", height:"730px"}}>
              <img  src={image2}  alt="complex" style={{height:"100%", width:"100%"}}/>
            </Box>
            <Box className='center'>
              <Box className='padding'>
                <Img alt="complex" src={image4} sx={{ height: "66px", width: "278px" }} />
                <Box sx={{ width: "65%", m: "auto", justifyContent: "start", display: "flex" }}>
                  <Typography variant="h4" color="text.primary" sx={{ pb: 2, pt: 2 }} >
                    Create your account
                  </Typography>
                </Box>
                {/* <div className='center'> */}
                <FormControl sx={{ m: 1, width: '65%', mb: 1 }}>
                  <TextField id="outlined-basic" label="Full Name" variant="outlined" />
                </FormControl>
                <FormControl sx={{ m: 1, width: '65%', mb: 1 }}>
                  <TextField id="outlined-basic" label="Email" variant="outlined" />
                </FormControl>
                <FormControl sx={{ m: 1, width: '65%', mb: 1 }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <FormControl sx={{ m: 1, width: '65%', mb: 3 }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <Button variant="contained" size="large" sx={{ width: '65%', backgroundColor: "rgba(247, 148, 51, 1)" }} onClick={() => navigate('welcome')}>
                  CREATE ACCOUNT
                </Button>
                <Box sx={{ width: "65%", m: "auto",  mt: 3 }}>
                  <Typography variant="body2" sx={{ textDecoration: 'none' }}>  Already have an account?
                    <Link to='/login1' style={{ color: '#1976d2' }}> Sign in</Link>
                  </Typography>
                </Box>
                <Box sx={{ width: "62%", m: "auto", pb: 1, mt: 1, display:"flex" }}>
               <ul >
                  <ListItem sx={{ display: 'list-item' ,p:0}}><Typography variant='caption' align='left'>Password Length matters; at least 8 characters               </Typography></ListItem>
                  <ListItem sx={{ display: 'list-item' ,p:0}}><Typography variant='caption' align='left'>Add some confusion; include special characters and/or numbers</Typography></ListItem>
                  <ListItem sx={{ display: 'list-item' ,p:0}}><Typography variant='caption' align='left'>Don't only play small ball; at least one uppercase character </Typography></ListItem>
                  <ListItem sx={{ display: 'list-item' ,p:0}}><Typography variant='caption' align='left'>Don't go all big either; at least one lowercase character    </Typography></ListItem>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Card>
        </Container>
      </Box>
      <Footer />
    </Box >
  )
}

export default RegisterMastech