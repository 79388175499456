import React from 'react'
import { Box, Link } from '@mui/material'
import advertisment from '../../assets/images/MicrosoftTeams-image.png'

const Advertisment = () => {
  return (
    <Box>
      <Link href="https://www.mastechdigital.com/engineering-staffing/top-qualities-for-hiring-engineering-staffing-agencies/" target="_blank">
      <img src={advertisment} alt="Advertisment" className="responsive-img"></img>
      </Link>
    </Box>
  )
}

export default Advertisment