import React from 'react'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Box, Checkbox, Container, FormControlLabel, ListItem } from '@mui/material';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import image3 from "../assets/images/thplogo.svg"
import image4 from '../assets/images/mastechLogo.svg';
import Footer from '../layouts/footer/Footer';
import image1 from "../assets/images/image1.png"
import { Link, useNavigate } from 'react-router-dom'
const Img = styled('img')({
  margin: 'auto',
});
const ResetPassMastech = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const [value, setValue] = React.useState(0);
  const navigate = useNavigate()
  // const ref = React.useRef(null);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        height: '100%',
        backgroundImage: `url(${image1})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Box sx={{ height: "94vh", alignItems: "center", justifyContent: "center", display: "flex", alignSelf: "center" }}>
        <Container fluid='true' sx={{ maxHeight: '650px', px: { xs: 1, sm: 6, md: 2, width: "542px" } }}>
          <Card sx={{ width: '100%', alignItems: 'center', display: 'flex', p: 0, height: "100%" }}>
            <Box className='center'>
              <Box className='padding'>
                <Img alt="complex" src={image4} sx={{ height: "66px", width: "278px" }} />
                <Box sx={{ width: "80%", m: "auto", py:2,justifyContent: "start", display: "flex" }}>
                  <Typography variant="h4" color="text.primary" >
                  Reset Password
                  </Typography>
                </Box>
                {/* <div className='center'> */}
               
                <FormControl sx={{ m: 'auto', width: '80%' ,py:1}}>
                  <TextField id="outlined-basic" label="Email" variant="outlined" />
                </FormControl>
                <FormControl sx={{ m: 'auto', width: '80%' ,py:1}}>
                  <TextField id="outlined-basic" label="Old Password" variant="outlined" />
                </FormControl>
                <FormControl sx={{ m: 'auto', width: '80%' ,py:1}}>
                  <TextField id="outlined-basic" label="New Password" variant="outlined" />
                </FormControl>
                <FormControl sx={{ m: 'auto', width: '80%' ,py:1}}>
                  <TextField id="outlined-basic" label="Confirm Password" variant="outlined" />
                </FormControl>
                <Button variant="contained" size="large" sx={{ width: '80%',my:2, backgroundColor: "rgba(247, 148, 51, 1)" }} onClick={() => navigate('welcome')}>
               UPDATE PASSWORD
                  {/* LOG IN  */}
                </Button>
                <Box sx={{ width: "75%", m: "auto", pb: 1, display:"flex" }}>
               <ul >
                  <ListItem sx={{ display: 'list-item' ,p:0}}><Typography variant='caption' align='left'>Password Length matters; at least 8 characters               </Typography></ListItem>
                  <ListItem sx={{ display: 'list-item' ,p:0}}><Typography variant='caption' align='left'>Add some confusion; include special characters and/or numbers</Typography></ListItem>
                  <ListItem sx={{ display: 'list-item' ,p:0}}><Typography variant='caption' align='left'>Don't only play small ball; at least one uppercase character </Typography></ListItem>
                  <ListItem sx={{ display: 'list-item' ,p:0}}><Typography variant='caption' align='left'>Don't go all big either; at least one lowercase character    </Typography></ListItem>
                  </ul>
                </Box>
                <Box sx={{ width: "80%", m: "auto", py: 1, }}>
                  <Typography variant="body2" sx={{ textDecoration: 'none' }}> 
                    <Link to='/login1' style={{ color: '#1976d2' }}>Continue to Sign in</Link>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Card>
        </Container>
      </Box>
      <Footer />
    </Box >
  )
}

export default ResetPassMastech