import React, { useEffect, useState } from 'react'
import {
  Card, Typography, Grid, Paper, Box, Avatar, Tab, List, LinkedIn, ListItem, ListItemButton, ListItemText, ListItemAvatar, styled, Chip, MoreVert, Divider, Tooltip, RadioGroup, Radio, FormLabel,
  MenuItem, FormControl, Email, Phone, Cake, Person, InputLabel, Checkbox, Select, FormGroup, Stack, FilterList, IconButton, PeopleOutline, FormControlLabel,
  WatchLaterOutlined, LocationOnOutlined, ArrowForwardOutlined, Button, Close, CardHeader
} from '../../utils/AllImportsHelper'
import { useDispatch } from 'react-redux';
import { closeDrawer } from '../../features/drawers/drawerSlice';
import ApiService from '../../utils/ApiService';
import { Link, useParams } from 'react-router-dom';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import DOMPurify from 'dompurify';
// import HiddenPost from '../../pages/HiddenPost';
import SkeletonCard from './SkeletonCard';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.08)',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  // border:'1px solid',
  color: 'theme.palette.text.secondary',
}));


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const ProfileListItem = styled(List)({
  '& .MuiListItemAvatar-root': {
    minWidth: '35px',
    marginTop: '5px',
  }
});

const UserviewProfile = (parentId) => {

  const { id } = useParams();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const [userProfile, setUserProfile] = useState([]);
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (id) {
      let params = { toolsUser: 'null' }
      ApiService.get('user', params, id).then((userProfile) => {
        // ApiService.get(`user`, tool, id).then((userProfile) => {
        setUserProfile(userProfile.data.data);
        // console.log(userProfile.data.data);
      });
    }
  }, [id]);

  const dispatch = useDispatch();
  const handleDrawerClose = () => {
    dispatch(closeDrawer(parentId));
  };

  const textStyle = {
    whiteSpace: 'nowrap',   // Prevent text from wrapping to the next line
    overflow: 'hidden',    // Hide any overflow text
    textOverflow: 'ellipsis', // Display ellipsis when text overflows
  };

  function formatDate(dateString) {
    // Create a Date object from the input date string
    const inputDate = new Date(dateString);

    // Define an array for month names
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'
    ];

    // Extract day and month from the input date
    const day = inputDate.getDate();
    const monthIndex = inputDate.getMonth();

    // Format the date as "DD Month"
    const formattedDate = `${day} ${months[monthIndex]}`;

    return formattedDate;
  }

  function extractYearFromDate(dateString) {
    const date = new Date(dateString);
    return date.getFullYear();
  }

  const sanitizeAndStyleHTML = (html) => {
    return DOMPurify.sanitize(html)
      .replace(/<strong>/g, '') // Remove <strong> tags
      .replace(/<\/strong>/g, '') // Remove </strong> tags
      .replace(/<ul>/g, '<div>')
      .replace(/<\/ul>/g, '</div>')
      .replace(/<li>/g, ' ')
      .replace(/<\/li>/g, '<br>');
  };

  return (

    //                 <Box>
    //                 <Grid container spacing={2}>
    //                    <Grid item xs={12} sm={12} md={12} lg={12}>

    //                             <Grid container spacing={2} alignItems="center">
    //                             <Grid item >
    //                             <Avatar sx={{ width: 60, height: 60 }}> <img src={userProfile.userAvatar} width='100%' /></Avatar>
    //                             </Grid>    
    //                                 <Grid item xs={9.5} sm={9.5}>
    //                                     <Tooltip title={userProfile.userName + ' ' + userProfile.lastName}>
    //                                         <Typography variant="body1" style={textStyle} fontWeight={500} >{userProfile.userName} {userProfile.lastName}</Typography>
    //                                     </Tooltip>
    //                                     <Typography variant="body2" color={'rgba(0, 0, 0, 0.5)'}>{userProfile?.designation?.designation}</Typography>
    //                                 </Grid>
    //                                </Grid>
    //                             <Grid item xs={12} sm={12} container spacing={1} py={2}>
    //                                 <Grid item xs={6} sm={4} color={'#475569'}>
    //                                     <Typography variant="body2" >Email ID:</Typography>
    //                                 </Grid>

    //                                 <Grid item xs={6} sm={8}>
    //                                     <Typography variant="body2">{userProfile.email}</Typography>
    //                                 </Grid>



    //                                 <Grid item xs={6} sm={4} color={'#475569'}>
    //                                     <Typography variant="body2">Report To:</Typography>
    //                                 </Grid>
    //                                 <Grid item xs={6} sm={8}>
    //                                     <Typography variant="body2">{userProfile?.supervisorName?.name} {userProfile?.supervisorName?.lastName}</Typography>
    //                                 </Grid>
    //                                 <Grid item xs={6} sm={4} color={'#475569'}>
    //                                     <Typography variant="body2">BirthDay:</Typography>

    //                                 </Grid>
    //                                 <Grid item xs={6} sm={8}>
    //                                     <Typography variant="body2">{formatDate(userProfile.dateOfBirth)}</Typography>
    //                                 </Grid>

    //                                 <Grid item xs={6} sm={4} color={'#475569'}>
    //                                     <Typography variant="body2">City:</Typography>

    //                                 </Grid>
    //                                <Grid item xs={6} sm={8}>
    //                                     <Typography variant="body2">{userProfile?.address}</Typography>
    //                                 </Grid>

    //                             </Grid>




    //                     </Grid>

    //                 </Grid>
    //                 {userProfile.skills?.length > 0 && (
    //   <>
    //     <Typography variant="body1" fontWeight={500} py={2}>
    //       Skills
    //     </Typography>
    //     <Grid container spacing={2}>
    //       {userProfile.skills?.map((skill,index) => (
    //         <Grid item sm={2.5} key={index}> 
    //         <Typography variant="body2" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.08)',p:1,textAlign:'center',borderRadius:'5px' }} >{skill?.skill}</Typography>
    //          </Grid>
    //       ))}
    //     </Grid>
    //   </>
    // )}



    //                 <Typography variant="body1" fontWeight={500} py={2} >Tools</Typography>
    //                 <Stack direction="row" spacing={2}>
    //                 {userProfile.toolsUser?.map((tools) => (
    //         <Item key={tools.id}>{tools?.tool.replace(/&nbsp;/g, '').replace(/(<([^>]+)>)/ig, "")}</Item>
    //         ))}
    //         </Stack>

    //                 </Box>
    <>
      <Typography variant="subtitle1" fontWeight={500} mb={1}>User Profile</Typography>
      { userProfile.length != '' ?
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <Card>
            <Card sx={{ p: 0, bgcolor: 'rgba(207, 174, 222, 0.46)' }}>
              <CardHeader

                avatar={
                  <Avatar sx={{ width: 70, height: 70 }} aria-label="recipe">
                    <img src={userProfile.userAvatar} width='100%' />
                  </Avatar>
                }

                title={<Typography variant="body1" fontWeight={500}>{userProfile.userName + ' ' + userProfile.lastName}</Typography>}
                subheader={
                  <Box sx={{ pt: 1 }}>
                    <Typography variant="body2">{userProfile?.designation?.designation}</Typography>
                    <Typography variant="body2">{userProfile?.employeeId}</Typography>
                  </Box>
                }
              />
            </Card>
            <ProfileListItem dense={dense}>

              <ListItem
                secondaryAction={
                  <Typography variant="caption">{userProfile.email}</Typography>
                }
              >
                <ListItemAvatar>
                  <Email fontSize="small" />
                </ListItemAvatar>
                <ListItemText
                primary={<Typography variant="subtitle2">Email</Typography>}
                // secondary={secondary ? 'Secondary text' : null}
                />
              </ListItem>
              <Divider />



              <ListItem
                secondaryAction={
                  <Typography variant="caption">{userProfile.contactNo}</Typography>
                }
              >
                <ListItemAvatar>
                  <Phone fontSize="small" />
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="subtitle2">Phone</Typography>}
                // secondary={secondary ? 'Secondary text' : null}
                />
              </ListItem>
              <Divider />



              <ListItem
                secondaryAction={
                  <Typography variant="caption">{formatDate(userProfile.dateOfBirth)}</Typography>
                }
              >
                <ListItemAvatar>
                  <Cake fontSize="small" />
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="subtitle2">Birthday</Typography>}
                // secondary={secondary ? 'Secondary text' : null}
                />
              </ListItem>
              <Divider />



              <ListItem
                secondaryAction={
                  <Typography variant="caption">{userProfile?.supervisorName?.name} {userProfile?.supervisorName?.lastName}</Typography>
                }
              >
                <ListItemAvatar>
                  <Person fontSize="small" />
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="subtitle2">Reports to:</Typography>}
                // {userProfile?.address}
                />
              </ListItem>
              <Divider />

              <ListItem
                secondaryAction={
                  <Typography variant="caption">{userProfile?.address}</Typography>
                }
              >
                <ListItemAvatar>
                  <LocationOnOutlined fontSize="small" />
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="subtitle2">Address</Typography>}
                // {userProfile?.address}
                />
              </ListItem>
              <Divider />

              <ListItem
                secondaryAction={
                  <a href={userProfile?.linkedin} target='_blank' rel='noopener noreferrer' style={{fontSize:'12px'}} >{userProfile?.linkedin}</a>
                }
              >
                <ListItemAvatar>
                  <LinkedIn fontSize="small" />
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="subtitle2">LinkedIn</Typography>}
                />
              </ListItem>

            </ProfileListItem>
          </Card>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Card>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} variant="scrollable" aria-label="lab API tabs example">
                  <Tab label="Profile" {...a11yProps(1)} value="1" />
                  <Tab label="Hidden Posts" {...a11yProps(2)} value="2" />
                  {/* <Tab label="Jobs" {...a11yProps(3)} value="3" /> */}
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ px: 0 }}>
                <Card>
                  {userProfile.skills?.length > 0 && (
                    <>
                      <Typography variant="body1" fontWeight={500} pb={1}>
                        Skills
                      </Typography>
                      <Divider />
                      {/* <Grid container spacing={2} pt={2}>
                        {userProfile.skills?.map((skill, index) => (
                          <Grid item sm={2.5} key={index}>
                            <Typography variant="body2" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.08)', p: 1, textAlign: 'center', borderRadius: '5px' }} >{skill?.skill}</Typography>
                          </Grid>
                        ))}
                      </Grid> */}
                     
                      {/* <Stack direction="row" spacing={2} > */}
                      <Grid container spacing={2} pt={2} >
                    {userProfile.skills?.map((skill,index) => (
                     <Grid item key={index}>
                        <Item>{skill?.skill}</Item>
                        </Grid>
                        ))}
                    </Grid>
                    
                  {/* </Stack> */}
                  {/* </Grid> */}
                    </>
                  )}
                </Card>
                <Card sx={{ my: 2 }}>
                  <Typography variant="body1" fontWeight={500} pb={1}>Tools</Typography>
                  <Divider />
                  {/* <Stack direction="row" spacing={2} pt={2}>
                    {userProfile.toolsUser?.map((tools) => (
                      <Item key={tools.id}>{tools?.tool}</Item>
                    ))}
                  </Stack> */}
                  <Grid container spacing={2} pt={2} >
                    {userProfile.toolsUser?.map((tools,index) => (
                     <Grid item key={index}>
                        <Item>{tools?.tool}</Item>
                        </Grid>
                        ))}
                    </Grid>
                </Card>

                <Card>
                  <Typography variant="body1" fontWeight={500} pb={1}>Education</Typography>
                  <Divider />

                  {userProfile.userEducation?.map((education, index) => (
                    <Grid spacing={2} container key={index} pt={2}>
                      <Grid item xs={5}>
                        <Typography variant="body2" fontWeight={500}>{extractYearFromDate(education?.fromYear)}-{education?.toYear}</Typography>
                        <Typography variant="body2" color='secondary.textSecondary'>{education?.degree}</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body2" fontWeight={500}>{education?.fieldOfStudy}</Typography>
                        <Typography variant="body2" color='secondary.textSecondary' >{education?.universityName}</Typography>
                      </Grid>
                    </Grid>
                  ))}


                </Card>

                <Card sx={{ my: 2 }}>
                  <Typography variant="body1" fontWeight={500} pb={1}>Employment</Typography>
                  <Divider />

                  {userProfile.userEmployment?.map((employment, index) => (
                    <CardHeader
                      sx={{ alignItems: 'flex-start' }}
                      avatar={
                        <Avatar sx={{ width: 40, height: 40 }} aria-label="recipe">
                          <img src={employment?.userAvatar} width='100%' />
                        </Avatar>
                      }

                      title={employment?.previousCompanyName}
                      subheader={
                        <Box sx={{ pt: 1 }}>
                          <Typography variant="body2">{(employment?.fromYear)} - {employment?.toYear}</Typography>
                          <Typography variant="body2">{employment?.designation}</Typography>
                          <Typography variant="body2">{employment?.department}</Typography>
                          <Typography variant="body2">{employment?.officeAddress}</Typography>
                        </Box>
                      }
                    />
                  ))}
                </Card>

                <Card>
                  <Typography variant="body1" fontWeight={500} pb={1}>Licenses & Certifications</Typography>
                  <Divider />

                  {userProfile.certificate?.map((certificates, index) => (
                    <CardHeader key={index}
                      sx={{ alignItems: 'flex-start' }}
                      avatar={
                        <Avatar sx={{ width: 40, height: 40 }} aria-label="recipe">
                          <img src={certificates?.userAvatar} width='100%' />
                        </Avatar>
                      }

                      title={<Typography fontWeight={500} textTransform='capitalize'>{certificates?.title}</Typography>}
                      subheader={
                        <>
                          {/* <Typography variant="body2">{certificates?.description}</Typography> */}
                          <Typography variant="body2" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sanitizeAndStyleHTML(certificates?.description)) }} />
                          <Box><img src={certificates?.file} width='100%' /></Box>
                        </>
                      }
                    />
                  ))}
                </Card>
              </TabPanel>
              {/* <TabPanel value="2" sx={{ px: 0 }}><HiddenPost /></TabPanel> */}
              {/* <TabPanel value="3" sx={{px:0}}><SavedJobs /></TabPanel> */}
            </TabContext>
          </Card>
        </Grid>
      </Grid>
      : <SkeletonCard />}
    </>


  )
}

export default UserviewProfile