import { Box, Card, Grid, TableContainer, Typography } from '@mui/material'
import React from 'react'
// import MyConnectLayout from '../CommonPage/MyConnectLayout'
import PostSkeleton from '../CommonPage/PostSkeleton'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Download, Work } from '@mui/icons-material';
import { Avatar } from '../../utils/AllImportsHelper';
import AnnouncementComponent from '../FeedPost.js/AnnouncementComponent';
import Advertisment from '../FeedPost.js/Advertisment';
import SharedDialog from '../../features/dialogBox/dialogBox';
import CampaignIcon from '@mui/icons-material/Campaign';



function Home({setFlag}) {
  const notification = [{ id: 1, title: "Mastech Digital Reports a 24% Year-over-Year Revenue Decline in the Third Quarter of 2023", textBoard: "Challenging Economic Conditions Impacted Both Business Segments during the Quarter Mastech Digital, Inc. (NYSE American: MHH), a leading provider of Digital Transformation IT Services, announced today its financial results for the third quarter ended September 30, 2023.", created_at: "2024-01-03T09:47:20.000000Z" },
  { id: 1, title: "MASTECH SAYS UNCERTAIN ECONOMY STILL WEIGHS ON Q3 IT STAFFING REVENUE", textBoard: "Third-quarter IT staffing revenue at Mastech Digital Inc. (NYSEAMERICAN: MHH) fell as clients responded to the uncertain US economy, the company reported today. Still, the pullback in demand was less pronounced than in the previous two quarters. The Pittsburgh-based firm also noted its “data and analytics services” segment was affected by project award delays.", created_at: "2024-01-03T09:47:20.000000Z" },
  { id: 1, title: "Message from Employer", textBoard: "Hi there! Your FNF is completed and created to your bank account", created_at: "2024-01-03T09:47:20.000000Z" }]

  const jobs = [{ id: 1, title: "Frontend Developer", textBoard: "We requires candidate who have knowledge of php OOP's concept and hands on experience in Laravel framework", created_at: "2024-01-03T09:47:20.000000Z" },
  { id: 1, title: "Laravel Developer", textBoard: "We requires candidate who have knowledge of php OOP's concept and hands on experience in Laravel framework", created_at: "2024-01-03T09:47:20.000000Z" },
  { id: 1, title: "Fullstack Developer", textBoard: "We requires candidate who have knowledge of php OOP's concept and hands on experience in Laravel framework", created_at: "2024-01-03T09:47:20.000000Z" }]
  function createData(name, icon) {
    return { name, icon };
  }

  const rows = [
    createData('Relieving Letter', <Download />),
    createData('Experience Letter', <Download />),
    createData('IT Computation Certificate', <Download />),
    createData('FNF Settlement', <Download />),
    createData('Recommendation Letter', <Download />),
    createData('Form 16 A', <Download />),

  ];
  return (
    <Box>
      {/* {
          posts.length ? */}
      <Box sx={{ minHeight: 'calc(95vh - 90px)' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}  sm={setFlag !== false ? 8 : 12} sx={{}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <Typography variant="h4">Welcome Vikram Singh !</Typography>
                  {/* <Typography variant="h7">Associate Software Engineer</Typography> */}
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <Grid container rowSpacing={1} sx={{ p: 2, pb: 3, "!important": true, display: 'flex', alignItems: 'center' }}>

                    <Grid item xs={11} sm={11} md={11} lg={7.5}>
                      <Box>
                        <Typography variant="h6">Download Your Files </Typography>
                      </Box>
                    </Grid>

                  </Grid>
                  <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Document name</TableCell>
                          <TableCell align="right">Download</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="right">{row.icon}</TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {/* <CreatePost /> */}
                </Card>
              </Grid>
              {/* } */}
              {/* <Grid item xs={12}>
              {posts.map((post, index) => {
                return (
                  <Card key={"" + post?.userName + index} sx={{ mb: 2 }}>
                    <Post post={post} isHeader={isHeader} isMore={isMore} isAwardTo={isAwardTo} isAwardHeader={isAwardHeader}
                      isCelebration={isCelebration} isBirthday={isBirthday} isJoiner={isJoiner} isPromotions={isPromotions}
                      isAccolades={isAccolades} isWorkAniversary={isWorkAniversary} isWeddings={isWeddings} isbirthAnnouncement={isbirthAnnouncement}
                      isStories={isStories} user={user} isEvent={isEvent} isLeaderSpeak={isLeaderSpeak} updateReaction={updateReaction} indivisualAPI={indivisualAPI} reactPost={reactPost} formTransform={formTransform} />
                  </Card>
                );

              })}
            </Grid> */}
            </Grid>
          </Grid>
          {/* {setFlag != false && */}
          <Grid item className='height-2' xs={12} sm={4}>
            <Grid container spacing={2}>
              {/* {isFeed && */}
              <Grid item xs={12}>
                <AnnouncementComponent notice={notification} title={"Latest News"} icon={ <CampaignIcon fontSize="small" />} redirectUrl={"/news"}/>
              </Grid>
              {/* } */}
              <Grid item xs={12}>
              <Grid item xs={12}>
                <AnnouncementComponent notice={jobs} title={"Latest Jobs"} icon={<Work fontSize="small" />}  redirectUrl={"/career"}/>
              </Grid>
              </Grid>

            </Grid>
          </Grid>
          {/* // } */}
        </Grid>
        {/* <SharedDialog id="editDialog">
          <CreatePostDialog id="editDialog" mode={'edit'} />
        </SharedDialog> */}

        {/* <SharedDialog id="reactionDialog">
          <ReactionPostDialog id="reactionDialog" mode={'Reaction'} />
        </SharedDialog> */}

        {/* <ConfirmDialogBox id='ConfirmDialog'
          sx={{ p: 2 }}
          title="Alert"
          body={<p>Are you sure you want to delete this post? This will remove the post and can't be undone.</p>}
          onCancel={closeDialogPublish}
          // onConfirm={() => handleConfirm()}
          titleOne="No,Cancel"
          titleTwo="Yes,Delete">
        </ConfirmDialogBox>
        <DynamicSnackbar /> */}
      </Box>
      {/* <MyConnectLayout notification={notification}/> */}
      {/* : <PostSkeleton />
        } */}
    </Box>
  )
}

export default Home
