import React from 'react'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Box, Checkbox, Container, FormControlLabel } from '@mui/material';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import image2 from '../assets/images/loginBanner.png';
import image3 from "../assets/images/thplogo.svg"
import image4 from '../assets/images/mastechLogo.svg';
import Footer from '../layouts/footer/Footer';
import image1 from "../assets/images/image1.png"
import { Link, useNavigate } from 'react-router-dom'
import './LoginMastech.css';
const Img = styled('img')({
  margin: 'auto',
});
const LoginMastech = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const [value, setValue] = React.useState(0);
  const navigate = useNavigate()
  // const ref = React.useRef(null);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  return (
    <Box
      component="div"
    className='login-background '
    >
      <Box className="login-container">
        <Container fluid='true' sx={{ maxHeight: '550px', px: { xs: 1, sm: 6, md: 5 } }}>
          <Card className='card'>
            <Box sx={{ width: '100%', }}>
              <Img alt="complex" src={image2} />
            </Box>
            <Box className='center'>
              <Box className='padding'>
                <Img alt="complex" src={image4}className='logo' />
                <Box sx={{ width:"65%", m:"auto",justifyContent:"start", display:"flex" }}>
                <Typography variant="h4" color="text.primary" sx={{ pb: 4, pt:4}} >
                  Login to your account
                </Typography>
                </Box>
                {/* <div className='center'> */}
                <FormControl sx={{ m: 'auto', width: '65%' }}>
                  <TextField id="outlined-basic" label="Email" variant="outlined" />
                </FormControl>
                <FormControl sx={{ m: 2, width: '65%', mb: 1 }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <Box sx={{ width: "65%", m: "auto", display: "flex",pb:1, justifyContent: "space-between" }}>
                  <Typography variant="body2" align="right" sx={{  textDecoration: 'none' }}>
                    <Link to='/reset1' style={{ color: '#1976d2' }}>Reset password?</Link>
                  </Typography>
                  <Typography variant="body2" align="right" sx={{  textDecoration: 'none' }}>
                    <Link to="/forgot1" style={{ color: '#1976d2' }}>Forgot password?</Link>
                  </Typography>
                </Box>
                <Box sx={{ width: "65%" ,m:"auto", justifyContent:"start",pb:1, display:"flex"}}>
                    <FormControlLabel  control={<Checkbox defaultChecked  size="small" />} label={<Typography variant="body2">Keep me sign in </Typography>} />
                </Box>
                <Button variant="contained" size="large" sx={{ width: '65%', backgroundColor: "rgba(247, 148, 51, 1)" }} onClick={() => navigate('welcome')}>
                  LOG IN
                  {/* LOG IN  */}
                </Button>
                <Box sx={{ width: "65%" ,m:"auto",pb:1, mt:3}}>
                 <Typography variant="body2" sx={{  textDecoration: 'none' }}>  New user?
                    <Link to='/register1' style={{ color: '#1976d2' }}> Create an account</Link>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Card>
        </Container>
      </Box>
      <Footer />
    </Box >
  )
}

export default LoginMastech