import React, {useState, useEffect} from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from "@mui/material";

const FormDateTimeField = (field) => {

    const [textField, setField] = useState(field.value);

    useEffect(() => {
        field.setTextField(textField)
    }, [textField])

    return ( <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker label={field.label} value={textField} onChange={(newValue) => {
          setField(newValue);
        }} renderInput={(params) => <TextField {...params} />}
          autoFocus={true} />
      </LocalizationProvider>)

}

export default FormDateTimeField;