import React from 'react'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Box, Checkbox, Container, FormControlLabel } from '@mui/material';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import image2 from '../assets/images/welcomePage.png';
import { forgotPasswordHandler } from "auth-component"

import image3 from "../assets/images/thplogo.svg"
import image4 from '../assets/images/image4.png';

import Footer from '../layouts/footer/Footer';
import image1 from "../assets/images/image1.png"
import { Link, useNavigate } from 'react-router-dom'
const Img = styled('img')({
  margin: 'auto',
});

const ForgotPass = () => {
  const [email, setEmail] = React.useState("");
  // const handleClickShowPassword = () => setShowPassword((show) => !show);
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  // const [value, setValue] = React.useState(0);
  const navigate = useNavigate()
  // const ref = React.useRef(null);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const submitClickHandler = (e) => {
    e.preventDefault();
    // console.log(window.location.origin);
    let postData = {};
    // handleDialogStatus()

    if (mailformat.test(email)) {
        postData.email = email;
    } else {
        // setEmailErr(true)
    }
    if (mailformat.test(email)) {
        postData.src = window.location.origin;

        forgotPasswordHandler(postData).then(response => {
            console.debug('mail has been sent');
            console.debug(response);
            if (response.status != 200) {
                // dispatch(setSnackBar({ dataLoad: true, message: response.detail, severity: "success" }));
                // setDailogMessage(response.detail)
                setTimeout(function () {
                    navigate("//login");
                }, 5000)
            } else {
                // setDailogMessage('Mail has been sent to your registered email')
                // dispatch(setSnackBar({ dataLoad: true, message: 'Mail has been sent to your registered email', severity: "success" }));
            }
            // handleDialogStatus()

        }).catch(e => {
            // dispatch(setSnackBar({ dataLoad: true, message: e.message, severity: "error" }));
        });
    }
}
  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        height: '100%',
        backgroundImage: `url(${image1})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Box sx={{ height: "94vh", alignItems: "center", justifyContent: "center", display: "flex", alignSelf: "center" }}>
        <Container fluid='true' sx={{ maxHeight: '550px', px: { xs: 1, sm: 6, md: 2, width: "542px" } }}>
          <Card sx={{ width: '100%', alignItems: 'center', display: 'flex', p: 0, height: "100%" }}>
            <Box className='center'>
              <Box className='padding'>
                <Img alt="complex" src={image4} sx={{height:66, width:150, mt:2}} />
                <Box sx={{ width: "80%", m: "auto", pb:2,justifyContent: "start", display: "flex" }}>
                  <Typography variant="h4" color="text.primary" sx={{ pt: 4 }} >
                    Forgot Password
                  </Typography>
                </Box>
                <Box sx={{ width: "80%", m: "auto",pb:2, justifyContent: "start", display: "flex" }}>
                  <Typography variant='body2' align='left' sx={{color:"rgba(97, 97, 97, 1)"}}>All you need to do is input your registered email address and leave the rest to us.</Typography>
                </Box>
                {/* <div className='center'> */}
                <FormControl sx={{ m: 'auto', width: '80%' ,py:3}}>
                  <TextField id="outlined-basic" label="Email" variant="outlined" />
                </FormControl>
                <Button variant="contained" size="large" sx={{ width: '80%',mb:3, backgroundColor: "rgba(37, 99, 235, 1)" }}    onClick={submitClickHandler}>
                SUBMIT
                  {/* LOG IN  */}
                </Button>
                <Box sx={{ width: "80%", m: "auto", pb: 1, my: 3 }}>
                  <Typography variant="body2" sx={{ textDecoration: 'none' }}> 
                    <Link to='/login' style={{ color: '#1976d2' }}>Continue to Sign in</Link>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Card>
        </Container>
      </Box>
      <Footer />
    </Box >
  )
}

export default ForgotPass