import React from 'react'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Box, Container, ListItem } from '@mui/material';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import image2 from '../assets/images/welcomePage.png';
import image3 from "../assets/images/thplogo.svg"
import image4 from '../assets/images/image4.png';
import Footer from '../layouts/footer/Footer';
import image1 from "../assets/images/image1.png"
import { Link, useNavigate } from 'react-router-dom'
// import { setSnackBar } from '../features/snakebar/snakebar';
import { useDispatch } from 'react-redux';
import { loginHandler } from "auth-component"


const Img = styled('img')({
  margin: 'auto',
});
const Register = () => {
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);


  const [errorMsg, setErrorMsg] = React.useState(false);
  const [passErr, setPassErr] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState(false);

  const [password, setPassword] = React.useState("");
  const [passwordErr, setPasswordErr] = React.useState(false);

  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = React.useState(false);


  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const passwordRegex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*()-_=+{};:,<.>?/\[\]\\\\]).{8,}$"


  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  // const [value, setValue] = React.useState(0);
  const navigate = useNavigate()
  // const ref = React.useRef(null);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  function onChangeHander(e) {
    if (e.target.name == "name") {
      setName(e.target.value)
    }
    if (e.target.name == "email") {
      setEmail(e.target.value)
      setEmailErr(false)

    }
    if (e.target.name == "password") {
      setPassword(e.target.value)
      setPasswordErr(false)

    }
    if (e.target.name == 'c_password') {
      setConfirmPassword(e.target.value)
      setConfirmPasswordErr(false)

    }
  }
  function loginClickHandler(e) {
    e.preventDefault();
    e.stopPropagation();
    let postData = {};
    postData.name = name;
    postData.email = email;
    postData.password = password;
    postData.c_password = confirmPassword;
    if (!email) {
      setEmailErr(true)
    }
    if (!password) {
      setPasswordErr(true)
    }
    if (!confirmPassword) {
      setConfirmPasswordErr(true);
    }
   

    if (email && password && (confirmPassword === password) && (mailformat.test(email))) {
      loginHandler(postData).then(r => {
        if (r.error) {
          setErrorMsg(<p style={{ color: "red" }}>These credentials do not match our records.</p>)
        }
        else {
          // dispatch(setSnackBar({ dataLoad: true, message: "Login successfull", severity: "success" }))
          if (r != null && r.redirectUrl) {
            window.location.assign(r.redirectUrl)
          } else {
            window.location.assign("/")
          }
        }
      }).catch(e => {
        // dispatch(setSnackBar({ dataLoad: true, message: e.message, severity: "error" }))
        console.debug("ssoLoginHandler : response : ", e);
      });
    }
    else {
      setEmailErr(<p>"Fields are mandatory"</p>)
    }
  }
  return (
    <Box
      component="div"
      sx={{
        // position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundImage: `url(${image1})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <Box sx={{ height: "94vh", alignItems: "center", justifyContent: "center", display: "flex", alignSelf: "center" }}>
        <Container fluid='true' sx={{ maxHeight: '720px', px: { xs: 1, sm: 6, md: 5 } }}>
          <Card sx={{ width: '100%', alignItems: 'center', display: 'flex', p: 0, height: "720px" }}>
            <Box sx={{ position: "relative", width: '100%', height: "720px" }}>
              <Img alt="complex" sx={{ width: "100%", height: "100%" }} src={image2} />
              <Box sx={{ position: "absolute", zIndex: 5, top: 0, m: 2, width: "100%" }}>
                <Img alt="complex" src={image3} />
                <Box sx={{ marginLeft: 5, color: "white", marginTop: 3 }}  >
                  <Typography variant='h3'>The Higher Pitch</Typography>
                  <Typography variant="body1">We are a performance marketing company</Typography>
                  <Typography variant="body1">  that specializes in  creating digital experience for enterprises.</Typography>
                </Box>
              </Box>
            </Box>
            <Box className='center'>
              <Box className='padding'>
                <Img alt="complex" src={image4} sx={{ height: "66px", width: "120px" }} />
                <Typography variant="h4" color="text.primary" sx={{ pb: 2, pt: 2 }} className="center">
                  Create your account
                </Typography>
                {/* <div className='center'> */}
                <FormControl sx={{ m: 'auto', width: '65%', mb: 2 }}>
                  <TextField id="outlined-basic"
                    type='text'
                    name="name"
                    onChange={(e) => onChangeHander(e)}
                    label="Name" variant="outlined" />
                </FormControl>

                <FormControl sx={{ m: 'auto', width: '65%' }}>
                  <TextField id="outlined-basic"
                    type='email'
                    error={emailErr || email &&!(mailformat.test(email))? true : false}
                    helperText={emailErr ? "Fields are mandatory " : email &&!(mailformat.test(email))?"Format are not correct ":false}
                    name="email"
                    onChange={(e) => onChangeHander(e)}
                    label="Email*" variant="outlined" />
                </FormControl>
                <FormControl sx={{ m: 2, width: '65%', mb: 1 }} variant="outlined">
                  <TextField
                    id="outlined-adornment-password"
                    name="password"
                    // error={emailErr}
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => onChangeHander(e)}
                    error={passwordErr ||password &&(!password.match(passwordRegex))? true : false}
                    helperText={passwordErr ? "Fields are mandatory " : (!passwordRegex.match(password))?"Password format not corrrect ":false}

                    InputProps={{
                      endAdornment:(
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                  }}
                   
                    label="Password *"
                  />
                </FormControl>
                <FormControl sx={{ m: 2, width: '65%', mb: 1 }} variant="outlined">
                  <TextField
                    id="outlined-adornment-password"
                    name="c_password"
                    error={confirmPasswordErr?true:false}
                    helperText={confirmPasswordErr ? "Fields are mandatory " : (!confirmPassword.match(password))?"Confirm password not match with password":false}

                    type={showConfirmPassword ? 'text' : 'password'}
                    onChange={(e) => onChangeHander(e)}
                    InputProps={{
                      endAdornment:(
                        <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                      )
                  }}
                   
                    label="Confirm Password *"
                  />
                </FormControl>
                <Button variant="contained" size="large" sx={{ width: '65%', backgroundColor: "rgba(37, 99, 235, 1)", mt: 2 }} onClick={(e) => loginClickHandler(e)}>
                  CREATE ACCOUNT
                  {/* LOG IN  */}
                </Button>
                <Box sx={{ width: "65%", m: "auto", mt: 3 }}>
                  <Typography variant="body2" sx={{ textDecoration: 'none' }}>  Already have an account?
                    <Link to='/login' style={{ color: '#1976d2' }}> Sign in</Link>
                  </Typography>
                </Box>
                <Box sx={{ width: "70%", m: "auto", pb: 1, mt: 1, display: "flex", pl: 2 }}>
                  <ul >
                    <ListItem sx={{ display: 'list-item', p: 0 }}><Typography variant='caption' align='left'>Password Length matters; at least 8 characters               </Typography></ListItem>
                    <ListItem sx={{ display: 'list-item', p: 0 }}><Typography variant='caption' align='left'>Add some confusion; include special characters and/or numbers</Typography></ListItem>
                    <ListItem sx={{ display: 'list-item', p: 0 }}><Typography variant='caption' align='left'>Don't only play small ball; at least one uppercase character </Typography></ListItem>
                    <ListItem sx={{ display: 'list-item', p: 0 }}><Typography variant='caption' align='left'>Don't go all big either; at least one lowercase character    </Typography></ListItem>
                  </ul>
                </Box>
              </Box>
            </Box>

          </Card>
        </Container>
      </Box>
      <Footer />
    </Box>
  )
}

export default Register