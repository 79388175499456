import React from "react";
import Sidebar from "../layouts/sidebar/Sidebar";
import ThemeProvider from "../theme";
import Breadcrumbs from "../component/Breadcrumbs";
import Home from "../component/Home/Home";
import BuildingNetworks from "../component/BuildingNetworks/BuildingNetworks";
import SupportDirectory from "../component/BuildingNetworks/SupportDirectory";
import News from "../component/News/News";
import Career from "../component/Career/Career";
import Helpdesk from "../component/Helpdesk/Helpdesk";
import UserviewProfile from "../component/MoreComponent/UserviewProfile";
import Login from "../pages/Login";
import LoginMastech from "../pages/LoginMastech";
import RegisterMastech from "../pages/RegisterMastech";
import ForgotPassMastech from "../pages/ForgotPassMastech";
import ResetPassMastech from "../pages/ResetPassMastech";
import Register from "../pages/Register";
import ForgotPass from "../pages/ForgotPass";
import JobDescription from "../component/JobReferral/JobDescription";

const windowObject = window;

const pagesConfig = [
  { path: "/", component: <Home />, sidebar: true },
  { path: "/login", component: <Login /> },
  { path: "/login1", component: <LoginMastech /> },
  { path: "/register", component: <Register /> },
  { path: "/register1", component: <RegisterMastech /> },
  { path: "/forgot", component: <ForgotPass /> },
  { path: "/forgot1", component: <ForgotPassMastech /> },
  { path: "/reset1", component: <ResetPassMastech /> },
  { path: "/reset", component: <ResetPassMastech /> },
  
  { path: "/profile", component: <UserviewProfile />, sidebar: true },
  { path: "/news", component: <News />, sidebar: true },
  { path: "/career", component: <Career />, sidebar: true },
  { path: "/job-description/:id", component: <JobDescription /> ,sidebar: true },
  { path: "/building-networks", component: <BuildingNetworks />, sidebar: true },
  { path: "/helpdesk", component: <Helpdesk />, sidebar: true },
  { path: "/contact-us", component: <SupportDirectory />, sidebar: true },
];

export function PrivateComponent() {
  const response = pagesConfig.map(({ path, component, sidebar }) => ({
    url: path,
    page: (
      <ThemeProvider key={path}>
        {sidebar && (
          <Sidebar window={windowObject}>
            {component}
          </Sidebar>
        )}
        {!sidebar && component}
      </ThemeProvider>
    ),
  }));

  return response;
}
