import React,{useState,useEffect} from 'react'
import ApiService from '../../../utils/ApiService';
import {
    Card, Typography, Grid, Box, styled, Chip, MoreVert, Divider, Tooltip, RadioGroup, Radio, FormLabel,
    MenuItem, FormControl, InputLabel, Checkbox, Select, FormGroup, Stack, FilterList, IconButton, PeopleOutline, FormControlLabel,
    WatchLaterOutlined, LocationOnOutlined, ArrowForwardOutlined, Button, Close
} from '../../../utils/AllImportsHelper'
import { useDispatch } from 'react-redux';
import { closeDrawer } from '../../../features/drawers/drawerSlice';

const SupportDirectoryDrawer = ({onUpdateFilters,parentId}) => {
    const [department, setDepartment] = React.useState([]);
    const [departmentData, setDepartmentData] = useState('');
    // console.log({departmentData})
    const [branch, setBranch] = useState([]);
    const [branchChange, setBranchChange] = useState('');

    useEffect(() => {
        ApiService.get('orgDepartment').then(department => {
            setDepartment(department.data.data)
                //  console.log(department.data.data)
              })

              ApiService.get('branch').then(branch => {
                setBranch(branch.data.data)
                    //  console.log(branch.data.data)
                  })

        }, []);

    const changeDepartmentSelectValue = (event) => {
        setDepartmentData(event.target.value);
    };

    const handleBranchChange = (event) => {
        setBranchChange(event.target.value);
        // console.log(event.target.value,{event})
    };

    const dispatch = useDispatch();
    const handleDrawerClose = () => {
        dispatch(closeDrawer(parentId));
    };

    const handleFilterSubmit = () => {
        if (
            departmentData ||
            branchChange !== '' 
            ) {
        const selectedFilters = {
            departmentData,
          branchChange,
       };
    
        // Pass the selected filter values to the parent component
        onUpdateFilters(selectedFilters);
        dispatch(closeDrawer());
      }
      else {
        // Optionally, you can show a message or prevent the submission if no options are selected
        // For example, display a message to the user or prevent the submission
        console.log('Please select at least one filter option.');
      }}
    return (
        <>
            {/* <Box sx={{minWidth:{sm:'var(--width-drawer-sm)', md:'var(--width-drawer-sm)', lg:'var(--width-drawer-sm)'}}}> */}
            <Box sx={{width:{sm:'30rem'}}}>
                <Stack sx={{ backgroundColor: "#DBEAFE" }}>
                    <Box sx={{ p: '.5rem .1rem' }}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={10}>
                                <Box pl='1rem' display='flex'>
                                    <Typography variant="body1" color="text.primary" fontWeight="700" display="block" >Filters</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton aria-label="Close Drawer" component="label" onClick={handleDrawerClose} color='black'>
                                    <Close sx={{ color: 'black' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Stack>
                <Box sx={{ height: '80vh', overflow: 'auto', p: 3 }}>
                    {/* <Typography variant="subtitle2" pb={1}>Location</Typography> */}
                   <Grid container spacing={2}>
                       

<Grid item sm={12} xs={12}>
                        <Typography variant="subtitle2" pb={1} color='textSecondary'>Department</Typography>
                            <FormControl fullWidth>
                            <InputLabel id="departmentSelectLabel">Department</InputLabel>
                                <Select
                                    labelId="departmentSelectLabel"
                                    id="departmentSelectBox"
                                     value={departmentData}
                                    label="Department"
                                    onChange={changeDepartmentSelectValue}
                                >
                                    {department.map((d) => { 
                                        // console.log(d.department)
                                        return (
                                    <MenuItem key={d.id} value={d.id} >{d.department}</MenuItem>
                                    )})}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <FormControl>

                                <Typography variant="subtitle2" color='textSecondary'>Branch</Typography>
                                <RadioGroup
                                    aria-labelledby="branch-buttons-group-label"
                                    value={branchChange}
                                    name="branch-radio-buttons-group"
                                    onChange={handleBranchChange}
                                >
                                    {branch.map((b) => { 
                                        //  console.log(d.department)
                                        return (
                                    <FormControlLabel key={b.id} value={b.id} control={<Radio />} label={b.branchName} />
                                    )})}
                                    {/* <FormControlLabel value="high" control={<Radio />} label="High" />
                                    <FormControlLabel value="immediate" control={<Radio />} label="Immediate" /> */}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>


                </Box>
                <Box sx={{ backgroundColor: 'white', width: '100%', position: 'absolute', bottom: '0', mt: '4rem', height: '4rem' }}>
                    <Stack sx={{ mx: '1rem', pt: '1rem' }} direction="row" spacing={1}>
                        <Button variant="contained" size="small" onClick={handleFilterSubmit} 
                         disabled={
                            departmentData.length == '' &&
                            !branchChange.length 
                          }
                        >SUBMIT</Button>
                        <Button variant="text" size="small" onClick={handleDrawerClose}>CANCEL</Button>
                    </Stack>
                </Box>
            </Box>
        </>
    )
}

export default SupportDirectoryDrawer