import { default as axios } from 'axios';
import { getAppToken, getSelfToken } from "../_helper/secureToken"
const BASE_URL = process.env.REACT_APP_BASEURL;
const MAIL_URL = process.env.REACT_APP_USERINFO;
const HELPDESK_URL = process.env.REACT_APP_HELPDESK;


class APIService {
  constructor(baseURL,mailURL,helpdeskURL) {
    // this.baseURL = baseURL || BASE_URL;
    // this.api = axios.create({
    //   baseURL: this.baseURL
    // });

    // Define your endpoints here
    this.endpoints = {
      menuSetting:"api/v1/menu-setting",
      myReferral:"v2/application",
      applyJob:"api/v1/application",
      tickets:"api/v1/ticket",
      

      // ----------- Filter Category -------
      userInfo: "api/v1/userinfo",
      FeedList: 'api/v1/posts',
      FeedAnnouncements: 'api/v1/notice',
      LeaderSpeak: 'api/v1/leader-speak',
      CompanyWithAHeart:'api/v1/social',
      Events:'api/v1/events',
      Social:'api/v1/social',
      Awards:"api/v1/award-user",
      Birthdays:"api/v1/user-birth",
      Joiners:"api/v1/user-joining",
      Promotions:"api/v1/promotion",
      Accolades:"api/v1/accolades",
      WorkAniversary:"api/v1/user-work-anniversary",
      birthAnnouncement:"api/v1/childs",
      Stories:"api/v1/recipes",
      Weddings:"api/v1/user-weddings",
      AssociateSpotlight:"api/v1/associates-feedback",
      jobFilter:"api/v1/jobs/new-filter",
      
      //-----Get----///
      savePost:'api/v1/posts',
      getReaction:'api/v1/postsreaction',

      //-------delete--//
      deletePost:'api/v1/posts',
      deletePostsReaction:'api/v1/postsreaction',

      //---------save post-----//
      savePostUser: 'api/v1/save-post',
      saveFavoriteFeeds: 'api/v1/favorite-feeds',

      //---------unsave post-----//
      unsavePostUser: 'api/v1/unsave-post',
      unsaveFavoriteFeeds: 'api/v1/favorite-feed-remove',

      //------post-//
      getPostsReaction:'api/v1/postsreaction',
      submitComment:'api/v1/feed-comments',

      //--------update-------------//
      updateReaction:'api/v1/postsreaction',
      // -------reaction ------- ///
      reactPost: 'api/v1/postsreaction',
      reactPostPages: 'api/v1/reaction',

      // ------- Org Chart -------//
      orgUsers: 'api/v1/users/hierarchy',
      orgDepartment: 'api/v1/department',
      orgDepartmentUser: 'api/v1/users',
      user: 'api/v1/user',
      branch: 'api/v1/branch',
      skills: 'api/v1/skills',
      jobs: 'api/v1/jobs/filter',
      getAllCity: 'api/v1/city',
      getAllCountry: 'api/v1/country',
      jobPriority : 'api/v1/job-priority',
      contactDirectory : 'api/v1/contact-directory',
      favouriteJob: 'api/v1/favorite-jobs',
      unsaveJob: 'api/v1/favorite-jobs-remove',
      jobDetails: 'api/v1/job',
      usersFilter: 'api/v1/users-filter',
      News:"api/v1/news",
     
    //  ------delete image edit post -------
    deleteImage: 'api/v1/images',
    deleteComment:'api/v1/feed-comments',
    updateComment:'api/v1/feed-comments',

    // --------- Referal ---------------///
    referalJob: 'api/v1/job',
    importantLinks: 'api/v1/link-type',
 // --------- Saved Posts ---------------///
    savedPosts: 'api/v1/saved-posts',
    favoriteFeeds:'api/v1/favorite-feeds',
    HiddenPost:'api/v1/hide-post'

    };

  this.api = axios.create({
    baseURL: baseURL
  });
  this.apiMail = axios.create({
    baseURL: mailURL
  });
  this.apiHelpdesk = axios.create({
    baseURL: helpdeskURL
  });
}
async getRequest(apiInstance, endpointKey, params = {}, searchParam = null) {
  try {
    let endpoint = this.endpoints[endpointKey];
    if (searchParam) {
      endpoint = endpoint + '/' + searchParam;
    }
    params.app = getAppToken();
    const response = await apiInstance.get(endpoint, {
      params,
      headers: { Authorization: 'Bearer ' + getSelfToken() },
    });
    return response;
  } catch (error) {
    throw new Error('API request failed:', error);
  }
}

async get(endpointKey, params = {}, searchParam = null) {
  return await this.getRequest(this.api, endpointKey, params, searchParam);
}

async getApi(endpointKey, params = {}, searchParam = null) {
  return await this.getRequest(this.apiMail, endpointKey, params, searchParam);
}
async getHelpdesk(endpointKey, params = {}, searchParam = null) {
  return await this.getRequest(this.apiHelpdesk, endpointKey, params, searchParam);
}
  async post(endpointKey, data = null, searchParam = null) {
    try {
      //console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data.app = getAppToken();
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }
  async postHelpdesk(endpointKey, data = null, searchParam = null) {
    try {
      //console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      // data.app = getAppToken();
      const response = await this.apiHelpdesk.post(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }
  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
      const response = await this.api.delete(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async put(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data['app'] = getAppToken();
      const response = await this.api.put(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }

  // Because some APIs are not in proper format we need to impliment another methods to handle 
  // The API's which are not following conventions, by which other APIs are following,
  // are :- unsaveFavoriteFeeds, 
  async postAdopter(endpointKey, data = null, searchParam = null) {
    try {
      //console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      endpoint = endpoint + '?app=' + getAppToken();
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }

}



export default new APIService(BASE_URL,MAIL_URL,HELPDESK_URL);
